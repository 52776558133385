var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('InstructionBox',{attrs:{"explainPoliticalSideTags":_vm.$store.state.configuration.overviewShowPoliticalSideTags,"explainPolarityTags":_vm.$store.state.configuration.overviewShowPolarityTags,"explainAnonymousTags":_vm.$store.state.configuration.overviewShowAnonymousTags,"mainEntityRepresentative":_vm.documentSet.mainEntity.shortRepresentative}}),_c('div',{staticClass:"flex"},[(_vm.mainDocument.imageUrl)?_c('div',{staticClass:"w-1/4 flex-col box-border pt-3 pb-2 pr-2"},[_c('router-link',{staticClass:"w-full h-full bg-cover bg-center block",style:(("background-image: url('" + (_vm.mainDocument.imageUrl) + "')")),attrs:{"to":_vm.useUrl ? {name: 'preview', params: {url: _vm.mainDocument.url, id: _vm.documentSet.topic}}
        : _vm.route(
            'article',
            { identifier: _vm.identifier, file: _vm.file, articleIndex: _vm.mainDocument.index }
            )}})],1):_vm._e(),_c('div',{staticClass:"flex-col",class:{'w-3/4': _vm.mainDocument.imageUrl}},[_c('h2',{staticClass:"article-headline"},[_c('router-link',{attrs:{"to":_vm.useUrl
          ? {name: 'preview', params: {url: _vm.mainDocument.url, id: _vm.documentSet.topic}}
          : _vm.route('article', { identifier: _vm.identifier, file: _vm.file, articleIndex: _vm.mainDocument.index })}},[_vm._v(" "+_vm._s(_vm.mainDocument.title ? _vm.mainDocument.title : '[MISSING TITLE]')+" ")]),(_vm.$store.state.configuration.overviewSentimentBars === 'show')?_c('EntityPolarityBarChart',{staticClass:"inline-block",attrs:{"barChartPolarities":_vm.mainDocument.sentimentBarData,"visualizeLarge":false}}):_vm._e(),_c('Tags',{attrs:{"showPoliticalOrientation":_vm.$store.state.configuration.overviewShowPoliticalSideTags,"showPolarity":_vm.$store.state.configuration.overviewShowPolarityTags,"showCluster":_vm.$store.state.configuration.overviewShowClusterTags,"showAnonymous":_vm.$store.state.configuration.overviewShowAnonymousTags,"document":_vm.documentSet.mainDocument}})],1),(_vm.mainDocument.leadParagraph
        && _vm.$store.state.configuration.overviewPlainShowRepresentativeLeadParagraph)?_c('p',{staticClass:"preview-paragraph"},[_c('router-link',{attrs:{"to":_vm.useUrl
          ? {name: 'preview', params: {url: _vm.mainDocument.url, id: _vm.documentSet.topic}}
          : _vm.route('article', { identifier: _vm.identifier, file: _vm.file, articleIndex: _vm.mainDocument.index })}},[_vm._v(_vm._s(_vm.mainDocument.leadParagraph)+" […]")])],1):_vm._e()])]),(_vm.$store.state.configuration.overviewPlainShowArticleCount !== 'off')?_c('span',{staticClass:"light article-count"},[(_vm.$store.state.configuration.overviewPlainShowArticleCount === 'number')?_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.documents.length))]):_c('span',[_vm._v("Further")]),_vm._v(" articles ")]):_vm._e(),_c('HeadlineList',{attrs:{"identifier":_vm.identifier,"documentSet":_vm.documentSet,"displayDocuments":_vm.documents.slice(1),"leadParagraphs":_vm.$store.state.configuration.overviewShowOtherLeadParagraphs,"polarityTags":_vm.$store.state.configuration.overviewShowPolarityTags,"politicalSideTags":_vm.$store.state.configuration.overviewShowPoliticalSideTags,"clusterTags":_vm.$store.state.configuration.overviewShowClusterTags,"anonymousTags":_vm.$store.state.configuration.overviewShowAnonymousTags,"showArticleBarChartPolarities":_vm.$store.state.configuration.overviewSentimentBars === 'show',"fullCoverage":_vm.fullCoverage,"max":5,"file":_vm.file}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fullCoverage),expression:"!fullCoverage"}],staticClass:"toggle-coverage",on:{"click":function($event){_vm.fullCoverage = true}}},[_vm._v(" View Full Coverage ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullCoverage),expression:"fullCoverage"}],staticClass:"toggle-coverage",on:{"click":function($event){_vm.fullCoverage = false}}},[_vm._v(" View Less Coverage ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }