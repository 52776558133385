function checkNumber(val) {
  if (typeof val !== 'number') {
    console.warn(`Comparing Polarity with non-number: ${val}`);
  }
}

function polarities() {
  return {
    POSITIVE_ABOVE: 0.33333,
    NEGATIVE_BELOW: -0.33333,
    POSITIVE: {
      isPositive: true,
      isNeutral: false,
      isNegative: false,
      isPositiveAndNegative: false,
      key: 'POSITIVE',
      value: 1,
      anonymousId: 1,
      text: 'possibly pro',
      class: 'pro',
      labelClass: 'label-positive',
      articleTooltipText: entityText => `Article is possibly pro: ${entityText} (determined automatically)`,
    },
    NEUTRAL: {
      isPositive: false,
      isNeutral: true,
      isNegative: false,
      isPositiveAndNegative: false,
      key: 'NEUTRAL',
      value: 0,
      anonymousId: 2,
      text: 'possibly ambivalent',
      class: 'ambivalent',
      labelClass: 'label-ambivalent',
      articleTooltipText: entityText => `Article is possibly neutral or ambivalent regarding: ${entityText} (determined automatically)`,
    },
    NEGATIVE: {
      isPositive: false,
      isNeutral: false,
      isNegative: true,
      isPositiveAndNegative: false,
      key: 'NEGATIVE',
      value: -1,
      anonymousId: 3,
      text: 'possibly contra',
      class: 'contra',
      labelClass: 'label-negative',
      articleTooltipText: entityText => `Article is possibly contra: ${entityText} (determined automatically)`,
    },
    POSITIVE_AND_NEGATIVE: {
      isPositive: false,
      isNeutral: false,
      isNegative: false,
      isPositiveAndNegative: true,
      key: 'POSITIVE_AND_NEGATIVE',
      value: 0,
      anonymousId: 4,
      text: 'possibly ambivalent',
      class: 'neutral',
      labelClass: 'label-neutral',
      articleTooltipText: entityText => `Article is possibly neutral or ambivalent regarding: ${entityText} (determined automatically)`,
    },
    all() {
      return [this.POSITIVE, this.NEUTRAL, this.NEGATIVE, this.POSITIVE_AND_NEGATIVE];
    },
    isNegative(val) {
      checkNumber(val);
      return val < this.NEGATIVE_BELOW;
    },
    isPositive(val) {
      checkNumber(val);
      return val > this.POSITIVE_ABOVE;
    },
    isNeutral(val) {
      checkNumber(val);
      return !this.isNegative(val) && !this.isPositive(val);
    },
    fromValue(val) {
      if (this.isNegative(val)) {
        return this.NEGATIVE;
      }
      if (this.isPositive(val)) {
        return this.POSITIVE;
      }
      return this.NEUTRAL;
    },
    copyFromValue(val, entityText) {
      const polarity = this.fromValue(val);
      return Object.assign(
        {},
        polarity,
        { articleTooltipText: polarity.articleTooltipText(entityText) },
      );
    },
  };
}

export default Object.freeze(polarities());
export { polarities };
