import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/overview/:file',
    name: 'overview',
    component: () => import('../views/Overview.vue'),
  },
  {
    path: '/article/:file/:identifier/:articleIndex',
    name: 'article',
    component: () => import('../views/ShowArticle.vue'),
  },
  {
    path: '/article/:file/:identifier',
    name: 'articles',
    component: () => import('../views/ShowArticle.vue'),
  },
  {
    path: '/preview/:id/:url',
    name: 'preview',
    component: () => import('../views/Preview.vue'),
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import('../views/Imprint.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/usage',
    name: 'usage',
    component: () => import('../views/Usage.vue'),
  },
  {
    path: '/bot',
    name: 'bot',
    component: () => import('../views/Bot.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
];


const router = new VueRouter({
  // mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  /*
  if (!['privacy-policy', 'imprint', 'usage'].includes(to.name)
  && !Cookies.get('cookiesAccepted')) {
    let redirect = btoa(JSON.stringify(to));
    if (to.query.redirect) {
      // eslint-disable-next-line prefer-destructuring
      redirect = to.query.redirect;
    }
    next({
      name: 'usage',
      query: {
        redirect,
      },
    });
  } else next();
  */
  next();
});

router.beforeResolve((to, from, next) => {
  if (!router.app.$store) {
    // At the first load, $store might be unavailable.
    next();
    return;
  }
  // the configuration depends on the iteration and some GET-params (hitId,
  // assignmentId, workerId) as well as (when for debugging enabled) to set
  // the config via the get parameters.
  // -> Update config when get params or iteration changed.
  // Also update when from.name is null (because then the page initially loaded)
  if (!_.isEqual(to.query, from.query)
      || to.params.iteration !== from.params.iteration
      || from.name === null) {
    router.app.$store.commit('updateLoading', +1);
    // Wait for configuration to load, so on creation of the new site the correct
    // config is loaded.
    router.app.updateConfiguration(to).then(() => {
      router.app.$store.commit('updateLoading', -1);
      next();
    });
  } else {
    next();
  }
});

export default router;
