/* eslint max-len: "off" */

import deepFreeze from 'deep-freeze-strict';


// Presets which are available for the non-mturk view.
const PRESETS = deepFreeze({
  defaultOverview: {
    // Default settings: First shown to the user and used when another preset does not contain a
    // setting value.
    overviewView: 'Anonymous-Clustering', // "AllSides", "Anonymous-AllSides", "MostFrequentActorPerception", "Anonymous-MostFrequentActorPerception", "Clustering", "Anonymous-Clustering", or "Plain"
    overviewShowArticleCount: false, // bool (only AllSides, Clustering, MFA)
    overviewShowPolarity: false, // bool (only AllSides)
    overviewShowPolarityTags: false, // bool
    overviewShowPoliticalSideTags: false, // bool
    overviewShowBiasCategories: false, // bool
    overviewShowOtherLeadParagraphs: 'click', // "off", "click", or "always"
    overviewPlainShowRepresentativeLeadParagraph: false, // bool
    overviewPlainShowArticleCount: 'off', // "off", "text", or "number"
    overviewPolarityMethod: 'absolute', // "random", "absolute", or "relative"
    overviewPolaritySeed: 'totallyRandomSeedString', // Any String
    overviewAllSidesMethod: 'original', // "original" or "random"
    overviewAllSidesSeed: 'totallyRandomSeedString',
    overviewClusterType: 'local_normalized', // "average", "local_normalized", or "global_normalized"
    overviewClusterAlgorithm: 'affinity_propagation', // "affinity_propagation" or "k_means"
    overviewClusterVectors: 'polarityClusters', // "polarityClusters", "newsframesClustersWithAttributes", "newsframesClustersOnlyDimensions"
    overviewExplanation: 'none', // "original", "specific", "generic", or "none"
    overviewShowClusterTags: false, // bool
    overviewShowAnonymousTags: true, // bool
    overviewAnonymousTagType: 'cluster', // "polarity", "politicalSide", or "cluster"
    overviewMainEntitySelection: 'mention_score', // "mention_score", or "appearances"
    overviewSentimentBars: 'hide', // "hide", "show", or "only_showcases"
    overviewSentimentBarMethod: 'mention_score', // "mention_score", or "appearances".
    overviewUseSummary: 'none', // "none", "all", "showcase", or "main"
  },
  defaultArticle: {
    articleHighlightMode: 'off', // "3-colors", "2-colors", "positive_and_negative_as_neutral", or "off"
    articleMainEntitySelection: 'mention_score', // "mention_score", or "appearances"
    articlePolarityMethod: 'absolute', // See overviewPolarityMethod
    articlePolaritySeed: 'totallyRandomSeedString', // See overviewPolaritySeed
    articleShowTopBiasCategories: false, // bool
    articleShowPolarityContext: false, // bool
    articleShowPolarity: false, // bool
    articleShowPolarityTags: false, // bool
    articleShowClusterTags: false, // bool
    articleClusterType: 'local_normalized', // "average", "local_normalized", or "global_normalized"
    articleClusterAlgorithm: 'affinity_propagation', // "affinity_propagation" or "k_means"
    articleShowAnonymousTags: false, // bool
    articleAnonymousTagType: 'polarity', // "polarity", "politicalSide", or "cluster"
    articleBiasCategoriesMode: 'off', // "tooltip", "off", "always" (deprecated)
    articleShowPoliticalSide: false, // bool
    articleShowPoliticalSideTags: false, // bool
    articleOpposingHeadlines: 'off', // "off", "anonymous_polarity", "anonymous_political_side", "anonymous_cluster", "polarity", "political_side", "cluster"
  },
  overview: {
    // Presents for the overview
    'Polarity Clusters': {},
    'NewsFrames Clusters (with attributes)': {
      overviewClusterVectors: 'newsframesClustersWithAttributes',
    },
    'NewsFrames Clusters (only dimensions)': {
      overviewClusterVectors: 'newsframesClustersOnlyDimensions',
    },
    Random: {
      overviewView: 'Anonymous-MostFrequentActorPerception',
      overviewPolarityMethod: 'random',
      overviewAnonymousTagType: 'polarity',
    },
  },
  article: {
    // Presets for the article-view; Names do not have to match the overview.
    '3 colors (minimal)': {
      articleHighlightMode: '3-colors', // "3-colors", "2-colors", "positive_and_negative_as_neutral", or "off"
    },
    '3 colors (full)': {
      articleHighlightMode: '3-colors',
      articleShowPolarityContext: true,
      articleShowPolarity: true,
      articleShowPolarityTags: true,
      articleShowAnonymousTags: true,
      articleAnonymousTagType: 'polarity',
      articleShowPoliticalSide: true,
      articleShowPoliticalSideTags: true,
    },
    '2 colors (minimal)': {
      articleHighlightMode: '2-colors', // "3-colors", "2-colors", "positive_and_negative_as_neutral", or "off"
    },
    '2 colors (full)': {
      articleHighlightMode: '2-colors',
      articleShowPolarityContext: true,
      articleShowPolarity: true,
      articleShowPolarityTags: true,
      articleShowAnonymousTags: true,
      articleAnonymousTagType: 'polarity',
      articleShowPoliticalSide: true,
      articleShowPoliticalSideTags: true,
    },
    'neutral (minimal)': {
      articleHighlightMode: 'positive_and_negative_as_neutral', // "3-colors", "2-colors", "positive_and_negative_as_neutral", or "off"
    },
    'neutral (full)': {
      articleHighlightMode: 'positive_and_negative_as_neutral',
      articleShowPolarityContext: true,
      articleShowPolarity: true,
      articleShowPolarityTags: true,
      articleShowAnonymousTags: true,
      articleAnonymousTagType: 'polarity',
      articleShowPoliticalSide: true,
      articleShowPoliticalSideTags: true,
    },
  },
});

const OVERVIEW_PRESETS = Object.freeze(Object.keys(PRESETS.overview));
const ARTICLE_PRESETS = Object.freeze(Object.keys(PRESETS.article));

const OVERVIEW_DEFAULT = OVERVIEW_PRESETS[Math.floor(Math.random() * OVERVIEW_PRESETS.length)];
const ARTICLE_DEFAULT = ARTICLE_PRESETS[Math.floor(Math.random() * ARTICLE_PRESETS.length)];

function getOverviewPreset(name = OVERVIEW_DEFAULT) {
  if (!PRESETS.overview[name]) {
    console.warn(`Unknown overview preset "${name}".`);
  }
  return { ...PRESETS.defaultOverview, ...PRESETS.overview[name] };
}

function getArticlePreset(name = ARTICLE_DEFAULT) {
  if (!PRESETS.article[name]) {
    console.warn(`Unknown article preset "${name}".`);
  }
  return { ...PRESETS.defaultArticle, ...PRESETS.article[name] };
}

export {
  PRESETS,
  OVERVIEW_PRESETS,
  ARTICLE_PRESETS,
  ARTICLE_DEFAULT,
  OVERVIEW_DEFAULT,
  getOverviewPreset,
  getArticlePreset,
};
