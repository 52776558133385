<template functional>
  <div class="instruction-box"
    v-if="props.explainPoliticalSideTags || props.explainPolarityTags
      || props.explainAnonymousTags">
    Tags shown near a headline denote
    <span v-if="props.explainPoliticalSideTags">
      the political orientation (as self-identified by the
      publisher) of its article
      (<span class="ml-0 label" :class="$options.POLITICAL_ORIENTATIONS.LEFT.labelClass">{{
        $options.POLITICAL_ORIENTATIONS.LEFT.text
      }}</span>
      <span class="label" :class="$options.POLITICAL_ORIENTATIONS.MIDDLE.labelClass">{{
        $options.POLITICAL_ORIENTATIONS.MIDDLE.text
      }}</span>
      <span class="label" :class="$options.POLITICAL_ORIENTATIONS.RIGHT.labelClass">{{
        $options.POLITICAL_ORIENTATIONS.RIGHT.text
      }}</span>)</span>
    <span
      v-if="props.explainPoliticalSideTags && props.explainPolarityTags
        && props.explainAnonymousTags"
    >, </span>
    <span
      v-if="props.explainPoliticalSideTags && props.explainPolarityTags
        && !props.explainAnonymousTags"
    > and </span>
    <span v-if="props.explainPolarityTags">
      how the article possibly portrays (determined automatically)
      the topic's main person
      <strong>{{ props.mainEntityRepresentative }}</strong>
      (<span class="ml-0 label" :class="$options.POLARITIES.NEGATIVE.labelClass">{{
        $options.POLARITIES.NEGATIVE.text
      }}</span>
      <span class="label" :class="$options.POLARITIES.NEUTRAL.labelClass">{{
        $options.POLARITIES.NEUTRAL.text
      }}</span>
      <span class="label" :class="$options.POLARITIES.POSITIVE.labelClass">{{
        $options.POLARITIES.POSITIVE.text
      }}</span>)<!--
    --></span>
    <span
      v-if="(props.explainPoliticalSideTags || props.explainPolarityTags)
        && props.explainAnonymousTags"
    > and </span>
    <span v-if="props.explainAnonymousTags">
      the article's main perspective on the topic
      (<span class="ml-0 label" :class="$options.PERSPECTIVES[0].labelClass">{{
        $options.PERSPECTIVES[0].name
      }}</span>
      <span class="label" :class="$options.PERSPECTIVES[1].labelClass">{{
        $options.PERSPECTIVES[1].name
      }}</span>
      <span class="label" :class="$options.PERSPECTIVES[2].labelClass">{{
        $options.PERSPECTIVES[2].name
      }}</span>)<!--
    --></span>.
  </div>
</template>
<script>
import { examples as PERSPECTIVES } from '@/libraries/AnonymousViewsHelpers.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import POLARITIES from '@/libraries/Polarities.js';

export default {
  name: 'InstructionBox',
  POLARITIES,
  POLITICAL_ORIENTATIONS,
  PERSPECTIVES,
  props: [
    'explainPoliticalSideTags',
    'explainPolarityTags',
    'explainAnonymousTags',
    'mainEntityRepresentative',
  ],
};
</script>
