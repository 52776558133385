const { VUE_APP_SURVEY_API_BASE } = process.env;

function apiRequest(suffix = null, init = null) {
  return new Request(`${VUE_APP_SURVEY_API_BASE}/${suffix}`, init);
}

export function questionsRequest(url = null, group = null) {
  let suffix = '';
  if (url) {
    suffix = `?url=${encodeURIComponent(url)}`;
  }
  if (group) {
    suffix += url ? '&' : '?';
    suffix += `group=${encodeURIComponent(group)}`;
  }
  return apiRequest(`questions/${suffix}`);
}

export function answerRequest(data) {
  return apiRequest('answer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
}
