<template>
  <div>
    <ThreeSides
      :identifier="identifier"
      :documents="documents"
      :documentSet="documentSet"
      :instructionBox="{}"
      :groupingInstructions="groupingInstructions"
      :previewNoPolarityTags="previewNoPolarityTags"
      :previewNoAnonymousTags="previewNoAnonymousTags"
      :onlyPreviewHeadlines="onlyPreviewHeadlines"
      :small-preview="smallPreview"
      :file="file"
      />
  </div>
</template>

<script>
import _ from 'lodash';
import ThreeSides from '@/components/overview/ThreeSides.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import POLARITIES from '@/libraries/Polarities.js';


const groupingInstructions = {
  name: 'MostFrequentActorPerceptionGroupingInstructions',
  template: `<span>Each article is randomly selected and assigned to either of the following groups
             depending on how the article portrays the main person. To determine how an article
             reports on the main person, we automatically classify the sentiment of all mentions of
             that person. Below, you see for each group its most representative article.<br /><br />
             In this topic, the main person is:
             <br />
             <div class="text-center font-bold text-xl">{{ mainEntity.shortRepresentative }}</div>
             </span>`,
  props: {
    mainEntity: {
      type: Object,
      required: true,
    },
  },
};

export default {
  name: 'MostFrequentActorPerception',
  components: {
    ThreeSides,
  },
  props: {
    documentSet: Object,
    identifier: String,
    onlyPreviewHeadlines: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      required: true,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: null,
      groupingInstructions,
      previewNoPolarityTags: true,
      previewNoAnonymousTags: false,
      // showRepresentativeLabel: process.env.VUE_APP_SHOW_REPRESANTATIVE_ARTICLE_LABEL === 'true',
    };
  },
  methods: {
    calculatePerceptionDocuments() {
      let pro = [];
      let neutral = [];
      let con = [];
      for (const doc of this.documentSet.documents) {
        const polarityValue = doc.mainEntityPolarityValue;
        const polarity = doc.mainEntityPolarity;
        if (polarity.isNegative) {
          con.push([doc, polarityValue]);
        } else if (polarity.isPositive) {
          pro.push([doc, polarityValue]);
        } else {
          neutral.push([doc, polarityValue]);
        }
      }
      con.sort((a, b) => a[1] - b[1]);
      pro.sort((a, b) => b[1] - a[1]);
      neutral.sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]));

      pro = pro.map(d => d[0]);
      neutral = neutral.map(d => d[0]);
      con = con.map(d => d[0]);

      this.setDocuments({
        mainEntity: this.documentSet.mainEntity,
        mainDocument: this.documentSet.mainDocument,
        firstSideDocuments: pro,
        secondSideDocuments: neutral,
        thirdSideDocuments: con,
        firstSidePolarity: POLARITIES.POSITIVE,
        secondSidePolarity: POLARITIES.NEUTRAL,
        thirdSidePolarity: POLARITIES.NEGATIVE,
        firstSideTitle: _.capitalize(POLARITIES.POSITIVE.text),
        secondSideTitle: _.capitalize(POLARITIES.NEUTRAL.text),
        thirdSideTitle: _.capitalize(POLARITIES.NEGATIVE.text),
        firstSideClass: POLARITIES.POSITIVE.class,
        secondSideClass: POLARITIES.NEUTRAL.class,
        thirdSideClass: POLARITIES.NEGATIVE.class,
      });
    },
    setDocuments(threeDocumentsData) {
      this.documents = new ThreeSidesDocuments(threeDocumentsData);
    },
  },
  created() {
    this.calculatePerceptionDocuments();
  },
  computed: {
    defaultGroupingInstructions() {
      return groupingInstructions;
    },
  },
};
</script>
