<script>
import _ from 'lodash';
import MostFrequentActorPerception
  from '@/components/overview/MostFrequentActorPerception.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import { threeSidesDocumentsSettings, groupingInstructions, groupingInstructionsSpecific }
  from '@/libraries/AnonymousViewsHelpers.js';

export default _.defaultsDeep({
  name: 'AnonymousMostFrequentActorPerception',
  data() {
    const { overviewExplanation } = this.$store.state.configuration;
    let instructions = groupingInstructions();
    if (overviewExplanation.startsWith('specific')) {
      instructions = groupingInstructionsSpecific();
    } else if (overviewExplanation === 'original') {
      instructions = this.defaultGroupingInstructions();
    }
    return {
      documents: null,
      groupingInstructions: instructions,
      previewNoPolarityTags: false,
      previewNoAnonymousTags: true,
    };
  },
  methods: {
    setDocuments(threeDocumentsData) {
      const tagType = this.$store.state.configuration.overviewAnonymousTagType;
      if (tagType !== 'polarity') {
        console.error('The anonymous tag type does not match the overviewView '
          + '(overviewView: AnonymousMostFrequentActorPerception; '
          + `anonymousTagType: ${tagType})`);
      }
      const {
        firstSideDocuments, secondSideDocuments, thirdSideDocuments,
      } = threeDocumentsData;

      const settings = _.cloneDeep(threeSidesDocumentsSettings);
      if (firstSideDocuments.length === 0) {
        settings.thirdSideClass = settings.secondSideClass;
        settings.secondSideClass = settings.firstSideClass;
      }
      if (secondSideDocuments.length === 0) {
        settings.thirdSideClass = settings.secondSideClass;
      }

      this.documents = new ThreeSidesDocuments(_.defaults(
        settings,
        {
          firstSideTitle: firstSideDocuments.length ? firstSideDocuments[0].anonymousGroup.name : '',
          secondSideTitle: secondSideDocuments.length ? secondSideDocuments[0].anonymousGroup.name : '',
          thirdSideTitle: thirdSideDocuments.length ? thirdSideDocuments[0].anonymousGroup.name : '',
        },
        threeDocumentsData,
      ));
    },
  },
}, MostFrequentActorPerception);

</script>
