import * as d3 from 'd3';
import POLARITIES from '@/libraries/Polarities.js';


export default class ArticleCountVisualization {
  /**
   * Create a new Article Count Pie Chart.
   * Arguments:
   *   root: Root element of the visualization, where elements will be appended.
   *   groups: Object where key is the name of the article group and the value
   *           (int) is the number of articles in that group.
   *   highlight (array of strings): Name of the groups to highlight in the
   *                                 visualization.
   *   dim (array of strings): Name of the groups which to dim in the viz.
   */
  constructor(
    root,
    groups,
    highlight = false,
    dim = false,
  ) {
    this.root = d3.select(root);
    this.groups = groups;
    this.highlight = highlight || [];
    this.dim = dim || [];
    this.strokewidth = 1;
    this.width = 30;
    this.height = 30;
    this.dimColor = 'rgb(229, 231, 235)';
    this.color = 'rgb(209, 213, 219)';
    this.highlightColor = 'rgb(107, 114, 128)';
    this.strokeColor = 'rgb(243, 244, 246)';
  }

  visualize() {
    // Adapted from: https://www.d3-graph-gallery.com/graph/pie_basic.html
    // The radius of the pieplot is half the width or half the height
    // (smallest one). I subtract a bit of margin.
    const radius = Math.min(this.width - this.strokewidth, this.height - this.strokewidth) / 2;

    // append the svg object
    const svg = this.root
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', `translate(${this.width / 2},${this.height / 2})`);

    // Compute the position of each group on the pie:
    const pie = d3.pie()
      .value(d => d.value);
    const dataReady = pie(d3.entries(this.groups));

    // Build the pie chart: Basically, each part of the pie is a path that we
    // build using the arc function.
    svg
      .selectAll()
      .data(dataReady)
      .enter()
      .append('path')
      .attr('stroke', this.strokeColor)
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .style('stroke-width', this.strokewidth)
      .attr('d', d3.arc()
        .innerRadius(0)
        .outerRadius(radius))
      .attr('fill', (d) => {
        if (this.highlight.includes(d.data.key)) {
          return this.highlightColor;
        }
        if (this.dim.includes(d.data.key)) {
          return this.dimColor;
        }
        return this.color;
      });
  }
}
