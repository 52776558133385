import Vue from 'vue';
import _ from 'lodash';
import TimeMe from 'timeme.js';
import Cookies from 'js-cookie';
import query from './graphql/Configuration.gql';
import App from './App.vue';
import router from './router';
import store from './store';

import 'balloon-css';
import 'typeface-playfair-display';
import 'fontsource-open-sans';
import 'css-loading';
import './assets/styles/index.scss';
import './assets/styles/articles.scss';
import './assets/styles/highlight-mode.scss';
import './assets/styles/survey.scss';
import { createProvider } from './vue-apollo.js';

Vue.config.productionTip = false;
Vue.prototype.$window = window;

const MTURK_GET = ['hitId', 'assignmentId', 'workerId'];
const LONG_TIMEOUT_ROUTE_NAMES = ['overview', 'article'];

Vue.mixin({
  methods: {
    route(name, newParams = {}) {
      const params = { ...this.$router.currentRoute.params, ...newParams };
      return {
        name,
        params,
        query: this.$route.query,
      };
    },
  },
});

const v = new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App),
  data() {
    return {
      statePopped: 0,
    };
  },
  methods: {
    updateConfiguration(newRoute = false) {
      return new Promise((resolve, reject) => {
        const route = newRoute || this.$route;
        this.$store.commit('updateMturk', route);
        resolve();
      });
    },
  },
  watch: {
    $route: function onRouteUpdate(newRoute) {
      this.$store.commit('routeUpdate', newRoute);
    },
  },
  mounted() {
    if (process.env.VUE_APP_MTURK_ONLY_MODE !== 'true') {
      this.$store.commit('useLastConfigurationPresets');
    }
    TimeMe.initialize({
      idleTimeoutInSeconds: 30,
    });
    TimeMe.startTimer();
    this.$store.commit('assignToCurrentTimingEvent', {
      url: this.$route.fullPath,
      routeName: this.$route.name,
    });
    if (this.$route.name !== null) {
      this.$store.commit('updateMturk', this.$route);
      this.skipConfigurationQuery = false;
    }
  },
  created() {
    window.addEventListener('popstate', (e) => {
      this.$store.commit('popState');
    });
  },
}).$mount('#app');
