<script>
import _ from 'lodash';
import AllSides from '@/components/overview/AllSides.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import { threeSidesDocumentsSettings, groupingInstructions, groupingInstructionsSpecific }
  from '@/libraries/AnonymousViewsHelpers.js';

export default _.defaultsDeep({
  name: 'AnonymousAllSides',
  data() {
    const { overviewExplanation, overviewUseSummary } = this.$store.state.configuration;
    const useSummary = ['all', 'showcase'].includes(overviewUseSummary);
    let instructions = groupingInstructions(useSummary);
    if (overviewExplanation === 'specific') {
      instructions = groupingInstructionsSpecific(useSummary);
    } else if (overviewExplanation === 'none') {
      instructions = null;
    } else if (overviewExplanation === 'original') {
      instructions = this.defaultGroupingInstructions();
    }
    return {
      documents: null,
      groupingInstructions: instructions,
      previewNoPoliticalSideTags: true,
      previewNoClusterTags: false,
      previewNoAnonymousTags: true,
    };
  },
  methods: {
    setDocuments(threeDocumentsData) {
      const tagType = this.$store.state.configuration.overviewAnonymousTagType;
      if (tagType !== 'political_side') {
        console.error('The anonymous tag type does not match the overviewView '
          + '(overviewView: AnonymousAllSides; '
          + `anonymousTagType: ${tagType})`);
      }
      const {
        firstSideDocuments, secondSideDocuments, thirdSideDocuments,
      } = threeDocumentsData;
      this.documents = new ThreeSidesDocuments(_.defaults(
        _.cloneDeep(threeSidesDocumentsSettings),
        {
          firstSideTitle: firstSideDocuments[0].anonymousGroup.name,
          secondSideTitle: secondSideDocuments.length ? secondSideDocuments[0].anonymousGroup.name : '',
          thirdSideTitle: thirdSideDocuments.length ? thirdSideDocuments[0].anonymousGroup.name : '',
        },
        threeDocumentsData,
      ));
    },
  },
}, AllSides);

</script>
