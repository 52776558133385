/* eslint no-underscore-dangle: 0 */
import POLARITIES from '@/libraries/Polarities.js';

const DEFAULT_CLASS = 'neutral';

export default class ThreeSidesDocuments {
  constructor({
    mainEntity,
    mainDocument,
    firstSideDocuments,
    secondSideDocuments,
    thirdSideDocuments,
    firstSidePolarity = POLARITIES.NEUTRAL,
    secondSidePolarity = POLARITIES.NEUTRAL,
    thirdSidePolarity = POLARITIES.NEUTRAL,
    firstSideClass = DEFAULT_CLASS,
    secondSideClass = DEFAULT_CLASS,
    thirdSideClass = DEFAULT_CLASS,
    firstSideTitle = '[NO FIRST SIDE TITLE]',
    secondSideTitle = '[NO SECOND SIDE TITLE]',
    thirdSideTitle = '[NO THIRD SIDE TITLE]',
    mainSummary = '[NO MAIN SUMMARY]',
    firstSideSummary = '[NO FIRST SIDE SUMMARY]',
    secondSideSummary = '[NO SECOND SIDE SUMMARY]',
    thirdSideSummary = '[NO THIRD SIDE SUMMARY]',
    firstSideEntitiesPolarities = false,
    secondSideEntitiesPolarities = false,
    thirdSideEntitiesPolarities = false,
    applyMainDocumentFilter = false,
    sentimentBarEntities = false,
    sortNonMainDocumentsFunction = (a, b) => Math.abs(b[0].representativeness)
                                             - Math.abs(a[0].representativeness),
  } = {}) {
    this._cache = {};
    this.applyMainDocumentFilter = applyMainDocumentFilter;
    this.mainDocument = mainDocument;
    this.mainEntity = mainEntity;
    this.firstSideDocuments = firstSideDocuments;
    this.secondSideDocuments = secondSideDocuments;
    this.thirdSideDocuments = thirdSideDocuments;
    this.firstSidePolarity = firstSidePolarity;
    this.secondSidePolarity = secondSidePolarity;
    this.thirdSidePolarity = thirdSidePolarity;
    this.firstSideClass = firstSideClass;
    this.secondSideClass = secondSideClass;
    this.thirdSideClass = thirdSideClass;
    this.sortNonMainDocumentsFunction = sortNonMainDocumentsFunction;
    this.firstSideTitle = firstSideTitle;
    this.secondSideTitle = secondSideTitle;
    this.thirdSideTitle = thirdSideTitle;
    this.mainDocumentGroup = null;
    this.mainSummary = mainSummary;
    this.firstSideSummary = firstSideSummary;
    this.secondSideSummary = secondSideSummary;
    this.thirdSideSummary = thirdSideSummary;
    this.firstSideEntitiesPolarities = firstSideEntitiesPolarities || [];
    this.secondSideEntitiesPolarities = secondSideEntitiesPolarities || [];
    this.thirdSideEntitiesPolarities = thirdSideEntitiesPolarities || [];
    if (sentimentBarEntities !== false) {
      const sides = [
        [this.firstSideDocuments, this.firstSideEntitiesPolarities],
        [this.secondSideDocuments, this.secondSideEntitiesPolarities],
        [this.thirdSideDocuments, this.thirdSideEntitiesPolarities],
      ];
      for (const [sideDocument, sideEntityPolarities] of sides) {
        let i = 0;
        for (const entityPolarity of sideEntityPolarities) {
          const entity = sentimentBarEntities[i];
          entityPolarity.count = ThreeSidesDocuments.sideMentions(entity, sideDocument);
          i += 1;
          // console.log(entityPolarity);
        }
      }
    }
  }

  get firstSideCount() {
    return this._firstSideDocuments.length + Number(this.mainDocumentGroup === 1);
  }

  get secondSideCount() {
    return this._secondSideDocuments.length + Number(this.mainDocumentGroup === 2);
  }

  get thirdSideCount() {
    return this._thirdSideDocuments.length + Number(this.mainDocumentGroup === 3);
  }

  set sortNonMainDocumentsFunction(sortFunction) {
    this._sortNonMainDocumentsFunction = sortFunction;
    delete this._cache.nonMainDocuments;
  }

  get sortNonMainDocumentsFunction() {
    return this._sortNonMainDocumentsFunction;
  }

  set firstSideDocuments(documents) {
    this._firstSideDocuments = this.filterMainDocument(documents, this.applyMainDocumentFilter, 1);
    delete this._cache.nonMainDocuments;
  }

  get firstSideDocuments() {
    return this._firstSideDocuments;
  }

  get numPerspectives() {
    if (this.firstSideCount === 0) {
      return 0;
    }
    if (this.secondSideCount === 0) {
      return 1;
    }
    if (this.thirdSideCount === 0) {
      return 2;
    }
    return 3;
  }

  set secondSideDocuments(documents) {
    this._secondSideDocuments = this.filterMainDocument(documents, this.applyMainDocumentFilter, 2);
    delete this._cache.nonMainDocuments;
  }

  get secondSideDocuments() {
    return this._secondSideDocuments;
  }

  set thirdSideDocuments(documents) {
    this._thirdSideDocuments = this.filterMainDocument(documents, this.applyMainDocumentFilter, 3);
    delete this._cache.nonMainDocuments;
  }

  get thirdSideDocuments() {
    return this._thirdSideDocuments;
  }

  static sideMentions(entity, side) {
    const sideIndices = new Set(side.map(x => x.index));
    return entity.members.map(m => sideIndices.has(m.document.index)).filter(Boolean).length;
  }

  filterMainDocument(documents, applyFilter, group) {
    if (!applyFilter) {
      return documents;
    }
    const sizeBefore = documents.length;
    const filtered = documents.filter(d => d !== this.mainDocument);
    if (sizeBefore > filtered.length) {
      this.mainDocumentGroup = group;
    }
    return filtered;
  }

  get firstSideMainDocument() {
    if (!this.firstSideDocuments) {
      return null;
    }
    return this.firstSideDocuments[0];
  }

  get secondSideMainDocument() {
    if (!this.secondSideDocuments) {
      return null;
    }
    return this.secondSideDocuments[0];
  }

  get thirdSideMainDocument() {
    if (!this.thirdSideDocuments) {
      return null;
    }
    return this.thirdSideDocuments[0];
  }

  get nonMainDocuments() {
    if (this._cache.nonMainDocuments) {
      return this._cache.nonMainDocuments;
    }
    const disallowed = [
      this.mainDocument,
      this.firstSideMainDocument,
      this.secondSideMainDocument,
      this.thirdSideMainDocument,
    ];
    this._cache.nonMainDocuments = [
      ...this.firstSideDocuments,
      ...this.secondSideDocuments,
      ...this.thirdSideDocuments,
    ]
      .filter(d => !disallowed.includes(d))
      .sort(this.sortNonMainDocuments);
    return this._cache.nonMainDocuments;
  }
}

export {
  DEFAULT_CLASS,
};
