<template>
  <div id="app" class="font-light h-full">
    <div
      class="absolute top-0 z-50 bottom-0 left-0 right-0 bg-white/30 border border-t-2
      border-gray-300 flex align-middle justify-center"
      style="backdrop-filter: blur(12px);"
      v-if="showCookieBanner"
    >
      <Usage
              class="bg-white rounded p-4 border-2 border-gray-500"
              style="height: fit-content;" @cookiesAccepted="onCookiesAccepted"
      />
    </div>
    <div
      v-if="$store.state.errors.size"
      class="errors"
    >
      <h1>Error!</h1>

      Following error(s) occured:

      <ul>
        <li v-for="error, i of $store.state.errors" :key="i">{{ error }}</li>
      </ul>

      This/These error(s) are most likely fatal and will not allow a continuation of the task.
      However, you can try to continue.

      <button
        class="discard"
        @click="$store.commit('removeErrors')"
      >
        Close message
      </button>
    </div>
    <div
      v-if="$store.state.warnings.size"
      class="warnings"
    >
      <h1>Warning!</h1>

      Following warning(s) occured:

      <ul>
        <li v-for="warning, i of $store.state.warnings" :key="i">{{ warning }}</li>
      </ul>

      You can still continue with the task and will be compensated.

      <button
        class="discard"
        @click="$store.commit('removeWarnings')"
      >
        Close message
      </button>
    </div>
    <nav>
      <router-link
        class="p-2 m-1 border bg-gray-300 rounded"
        :to="route('home')">newsalyze
      </router-link>
      <router-link :to="route('home')">Home</router-link>
      <!-- <router-link :to="route('about')">About</router-link> -->
      <div class="preset-box" v-if="!hidePresetBox">
        <label for="overview-preset">Overview Preset:</label>
        <select id="overview-preset" v-model="overviewPreset">
          <template v-for="preset of OVERVIEW_PRESETS">
            <option :key="preset" :value="preset">{{ preset }}</option>
          </template>
        </select>
        <label for="article-preset">Article Preset:</label>
        <select id="article-preset" v-model="articlePreset">
          <template v-for="preset of ARTICLE_PRESETS">
            <option :key="preset" :value="preset">{{ preset }}</option>
          </template>
        </select>
        <button
          v-show="presetChanged"
          aria-label="Some changes require a reload to take effect!"
          data-balloon-pos="left"
          @click="reload()"
        >
          &circlearrowright;
        </button>
      </div>
    </nav>
    <div
      id="content"
      class="normal-mode pt-16 pb-3"
      :class="{'center-content': $route.name !== 'preview'}">
      <div
        class="center-content loading-info"
        v-show="$store.state.loading">
        <div data-loader="circle-side"></div>
        Loading... Stay tuned...
      </div>
      <div class="flex min-h-full border-box" style="margin-top: -.6rem;">
        <router-view
                v-if="$store.state.configuration !== null"
                @cookiesRevoked="onCookiesRevoked" />
      </div>
      <div
        class="center-content loading-info"
        v-show="$store.state.loading">
        <div data-loader="circle-side"></div>
        Loading... Stay tuned...
      </div>
      <footer class="text-center">
        <router-link to="imprint" class="underline">Imprint</router-link> /
        <router-link to="privacy-policy" class="underline">Privacy Policy</router-link> /
        <router-link to="usage" class="underline">Usage and Cookies</router-link>
      </footer>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { ARTICLE_PRESETS, OVERVIEW_PRESETS } from '@/libraries/ConfigurationPresets';
import Usage from '@/views/Usage.vue';
import router from '@/router';

const ACCESSIBLE_WITHOUT_COOKIE = ['privacy-policy', 'imprint', 'usage', 'bot'];

const app = {
  name: 'app',
  components: { Usage },
  data() {
    const showCookieBanner = !Cookies.get('cookiesAccepted');
    return {
      ARTICLE_PRESETS,
      OVERVIEW_PRESETS,
      articlePreset: this.$store.getters.articlePresetName,
      overviewPreset: this.$store.getters.overviewPresetName,
      hidePresetBox: true,
      presetChanged: false,
      showCookieBanner,
    };
  },
  mounted() {
    console.info('Run `window.showNewsalyzePresetBox()` to change the default presets.');
    window.showNewsalyzePresetBox = () => {
      this.hidePresetBox = false;
    };
  },
  methods: {
    reload() {
      this.$window.location.reload();
    },
    onCookiesRevoked() {
      this.showCookieBanner = true;
    },
    onCookiesAccepted() {
      this.showCookieBanner = false;
    },
  },
  computed: {
    mturkError() {
      const {
        assignmentId,
        hitId,
        workerId,
      } = this.$store.state.mturk;
      return !assignmentId && !hitId && !workerId;
    },
    progress() {
      if (this.$route.path === '/') {
        return 0;
      }
      return Math.floor(this.$store.state.configuration.progress * 100);
    },
    showProgress() {
      return this.$route.query.popup !== '1'
        && this.$store.getters.mturkEnabled
        && this.$store.state.configuration !== null;
    },
  },
  watch: {
    articlePreset(value) {
      this.$store.commit('useArticleConfigurationPreset', value);
      this.presetChanged = true;
    },
    overviewPreset(value) {
      this.$store.commit('useOverviewConfigurationPreset', value);
      this.presetChanged = true;
    },
    $route(current) {
      if (ACCESSIBLE_WITHOUT_COOKIE.includes(current.name)) {
        this.showCookieBanner = false;
      } else {
        this.showCookieBanner = !Cookies.get('cookiesAccepted');
      }
    },
  },
};
export default app;
</script>
