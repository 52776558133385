<template>
  <section>
    <InstructionBox
      :explainPoliticalSideTags="$store.state.configuration.overviewShowPoliticalSideTags"
      :explainPolarityTags="$store.state.configuration.overviewShowPolarityTags"
      :explainAnonymousTags="$store.state.configuration.overviewShowAnonymousTags"
      :mainEntityRepresentative="documentSet.mainEntity.shortRepresentative" />

    <div class="flex">
      <div class="w-1/4 flex-col box-border pt-3 pb-2 pr-2" v-if="mainDocument.imageUrl">
        <router-link
          :to="useUrl ? {name: 'preview', params: {url: mainDocument.url, id: documentSet.topic}}
          : route(
              'article',
              { identifier, file, articleIndex: mainDocument.index }
              )"
          class="w-full h-full bg-cover bg-center block"
          :style="`background-image: url('${mainDocument.imageUrl}')`"
        ></router-link>
      </div>
      <div class="flex-col" :class="{'w-3/4': mainDocument.imageUrl}">
        <h2 class="article-headline">
          <router-link
            :to="useUrl
            ? {name: 'preview', params: {url: mainDocument.url, id: documentSet.topic}}
            : route('article', { identifier, file, articleIndex: mainDocument.index })">
            {{ mainDocument.title ? mainDocument.title : '[MISSING TITLE]' }}
          </router-link>
          <EntityPolarityBarChart
            v-if="$store.state.configuration.overviewSentimentBars === 'show'"
            class="inline-block"
            :barChartPolarities="mainDocument.sentimentBarData"
            :visualizeLarge="false" />
          <Tags
            :showPoliticalOrientation="$store.state.configuration.overviewShowPoliticalSideTags"
            :showPolarity="$store.state.configuration.overviewShowPolarityTags"
            :showCluster="$store.state.configuration.overviewShowClusterTags"
            :showAnonymous="$store.state.configuration.overviewShowAnonymousTags"
            :document="documentSet.mainDocument"
          />
        </h2>
        <p
          v-if="mainDocument.leadParagraph
          && $store.state.configuration.overviewPlainShowRepresentativeLeadParagraph"
          class="preview-paragraph">
          <router-link
            :to="useUrl
            ? {name: 'preview', params: {url: mainDocument.url, id: documentSet.topic}}
            : route('article', { identifier, file, articleIndex: mainDocument.index })">{{
              mainDocument.leadParagraph
            }} [&mldr;]</router-link>
        </p>
      </div>
    </div>
    <span
      v-if="$store.state.configuration.overviewPlainShowArticleCount !== 'off'"
      class="light article-count">
      <span
        class="number"
        v-if="$store.state.configuration.overviewPlainShowArticleCount === 'number'">{{
          documents.length
        }}</span>
        <span v-else>Further</span>
      articles
    </span>
    <!--<div
      class="bias-categories float-right"
      v-if="false && $store.state.configuration.overviewShowBiasCategories !== 'off'">
      <span
        v-for="category, i in categories"
        :key="i"
        :class="{
          'positive': category[2] === 1,
          'negative': category[2] === -1,
          }"
        >{{ category[0] + (i+1 &lt; categories.length ? ', ' : '') }}</span>
    </div>-->
    <HeadlineList
      :identifier="identifier"
      :documentSet="documentSet"
      :displayDocuments="documents.slice(1)"
      :leadParagraphs="$store.state.configuration.overviewShowOtherLeadParagraphs"
      :polarityTags="$store.state.configuration.overviewShowPolarityTags"
      :politicalSideTags="$store.state.configuration.overviewShowPoliticalSideTags"
      :clusterTags="$store.state.configuration.overviewShowClusterTags"
      :anonymousTags="$store.state.configuration.overviewShowAnonymousTags"
      :showArticleBarChartPolarities="$store.state.configuration.overviewSentimentBars === 'show'"
      :fullCoverage="fullCoverage"
      :max="5"
      :file="file"
       />
    <span
      v-show="!fullCoverage"
      class="toggle-coverage"
      @click="fullCoverage = true">
      View Full Coverage
    </span>
    <span
      v-show="fullCoverage"
      class="toggle-coverage"
      @click="fullCoverage = false">
      View Less Coverage
    </span>
  </section>
</template>

<script>
import DocumentParser from '@/libraries/DocumentParser.js';
import HeadlineList from '@/components/overview/HeadlineList.vue';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import InstructionBox from '@/components/overview/InstructionBox.vue';
import Tags from '@/components/Tags.vue';
import EntityPolarityBarChart from '@/components/EntityPolarityBarChart.vue';

export default {
  name: 'Plain',
  components: {
    Tags,
    InstructionBox,
    EntityPolarityBarChart,
    HeadlineList,
  },
  props: {
    documentSet: Object,
    identifier: String,
    file: {
      type: String,
      required: true,
    },
  },
  POLITICAL_ORIENTATIONS,
  data() {
    return {
      fullCoverage: false,
      useUrl: process.env.VUE_APP_LINK_TO_ARTICLE_VIEW === '0',
    };
  },
  computed: {
    documents() {
      return [...this.documentSet.documents]
        .sort((a, b) => b.representativeness - a.representativeness);
    },
    mainDocument() {
      return this.documentSet.mainDocument;
    },
  },
};
</script>
