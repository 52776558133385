import * as d3 from 'd3';
import POLARITIES from '@/libraries/Polarities.js';


export default class EntityPolarityBarChart {
  /**
   * Create a new polarity visualization.
   * Arguments:
   *   root: Root element of the visualization, where elements will be appended.
   *   barChartPolarities: Array[Object[{ name: str, value: float }]] Sentiments
   *     of different entities and their polarity value
   */
  constructor(
    root,
    barChartPolarities,
  ) {
    this.root = d3.select(root);
    this.barChartPolarities = barChartPolarities;
    this.elementHeight = 20; // Height of one entity-row.
    this.textWidth = 50;
    this.leftSvg = null;
    this.centerDiv = null;
    this.rightSvg = null;
    this.barHeight = 16;
    this.negativeColor = 'rgb(248, 113, 113)'; // bg-red-400
    this.positiveColor = 'rgb(52, 211, 153)'; // bg-green-400
    this.borderLeftColor = 'rgb(185, 28, 28)'; // bg-red-700
    this.borderRightColor = 'rgb(4, 120, 87)'; // bg-red-700
    this.neutralBackgroundColor = 'rgb(209, 213, 219, 0)';
    this.svgBackground = 'rgba(229, 231, 235, 0)';
    this.borderWidth = '2px';

    this.smallHeight = 24;
    this.smallWidth = 61;
    this.smallBarHeight = 6;
    this.smallBarPadding = 1;
    this.smallDividerWidths = 1;
    this.smallBackground = 'rgb(243, 244, 246)';
    this.centerBarColor = 'rgb(55, 65, 81)';
  }

  createText(text, grayed = false) {
    const span = this.centerDiv.append('span');
    if (grayed) {
      span.style('color', '#999');
    }
    return span.style('display', 'block')
      .style('text-align', 'center')
      .style('line-height', '12px')
      .style('padding', '5px 1px')
      .style('overflow-word', 'break-word')
      .style('word-wrap', 'break-word')
      .style('hyphens', 'auto')
      .style('border-style', 'solid')
      .style('border-left-color', this.borderLeftColor)
      .style('border-right-color', this.borderRightColor)
      .style('border-width', `0 ${this.borderWidth}`)
      .text(text);
  }

  createElement(tag) {
    return this.root.append(tag)
      .style('width', '33%')
      .style('display', 'inline-block')
      .style('vertical-align', 'top');
  }

  createBar(value, svg, textElement) {
    const { y, height } = textElement.node().getBoundingClientRect();
    if (value === 0 || Number.isNaN(value)) {
      textElement.style('background-color', this.neutralBackgroundColor);
      return Math.max(height, this.barHeight);
    }
    let color = this.positiveColor;
    let multiplier = 0;
    if (value < 0) {
      color = this.negativeColor;
      multiplier = 1;
    }
    const barCenter = y - svg.node().getBoundingClientRect().y + (height / 2);
    const percentage = Math.abs(value) * 100;
    svg.append('rect')
      .attr('y', barCenter - this.barHeight / 2)
      .attr('x', `${multiplier * (100 - percentage)}%`)
      .attr('width', `${percentage}%`)
      .attr('height', this.barHeight)
      .style('fill', color);
    return Math.max(height, this.barHeight);
  }

  visualizeLarge() {
    this.leftSvg = this.createElement('svg').style('background-color', this.svgBackground);
    this.centerDiv = this.createElement('div');
    this.rightSvg = this.createElement('svg').style('background-color', this.svgBackground);
    let height = 0;
    for (const { name, value, count } of this.barChartPolarities) {
      const textElement = this.createText(`${name}`, count === 0);
      height += this.createBar(value, value > 0 ? this.rightSvg : this.leftSvg, textElement);
    }
    this.leftSvg.style('height', `${height}px`);
    this.rightSvg.style('height', `${height}px`);
  }

  visualizeSmall() {
    const svg = this.root.append('svg')
      .style('display', 'inline-block')
      .style('vertical-align', 'top')
      .style('width', `${this.smallWidth}px`)
      .style('height', `${this.smallHeight}px`)
      .style('border-style', 'solid')
      .style('border-left-color', this.borderLeftColor)
      .style('border-right-color', this.borderRightColor)
      .style('background-color', this.smallBackground)
      .style('border-width', `0 ${this.smallDividerWidths}px`);
    svg.append('line')
      .attr('x1', '50%')
      .attr('y1', 0)
      .attr('x2', '50%')
      .attr('y2', '100%')
      .attr('stroke-width', 1)
      .attr('stroke', this.centerBarColor);
    for (const [i, { name, value }] of this.barChartPolarities.entries()) {
      if (value === 0.0 || value === undefined) {
        continue;
      }
      const y = i * this.smallBarHeight + i * 2 * this.smallBarPadding + this.smallBarPadding;
      let x = '50%';
      let fill = this.positiveColor;
      if (value < 0) {
        x = `${50 - Math.abs(value * 50)}%`;
        fill = this.negativeColor;
      }
      svg.append('rect')
        .attr('y', y)
        .attr('x', x)
        .attr('width', `${Math.abs(value) * 50}%`)
        .attr('height', this.smallBarHeight)
        .attr('fill', fill);
    }
  }
}
