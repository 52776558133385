<template>
  <div>
    <ThreeSides
      :identifier="identifier"
      :documents="documents"
      :documentSet="documentSet"
      :instructionBox="{}"
      :groupingInstructions="groupingInstructions"
      :previewNoPoliticalSideTags="previewNoPoliticalSideTags"
      :previewNoAnonymousTags="previewNoAnonymousTags"
      :onlyPreviewHeadlines="onlyPreviewHeadlines"
      :file="file"
      :small-preview="smallPreview"
      />
  </div>
</template>

<script>
import ThreeSides from '@/components/overview/ThreeSides.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';

function groupingInstructions(useSummary) {
  let belowText = 'Below, you see for each group its most representative article.';
  if (useSummary) {
    belowText = 'Below, you see for each group a summary.';
  }

  return {
    name: 'AllSidesGroupingInstructions',
    template: `<span>Each article is assigned to either of the following groups depending on its
               news outlet’s political orientation (left, center, right). We use political
               orientation as self-defined by the outlet. ${belowText}</span>`,
  };
}

export default {
  name: 'AllSides',
  components: {
    ThreeSides,
  },
  data() {
    return {
      previewNoPoliticalSideTags: true,
      previewNoAnonymousTags: false,
      documents: null,
      groupingInstructions: this.defaultGroupingInstructions(),
    };
  },
  props: {
    documentSet: Object,
    identifier: String,
    onlyPreviewHeadlines: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      required: true,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const documents = {
      [POLITICAL_ORIENTATIONS.LEFT.key]: [],
      [POLITICAL_ORIENTATIONS.MIDDLE.key]: [],
      [POLITICAL_ORIENTATIONS.RIGHT.key]: [],
    };
    for (const doc of this.documentSet.documents) {
      documents[doc.politicalBias.side].push(doc);
    }
    for (const docs of Object.values(documents)) {
      docs.sort((x, y) => (y.politicalBias.probability - x.politicalBias.probability));
    }
    const firstSentimentBarEntities = [];
    const secondSentimentBarEntities = [];
    const thirdSentimentBarEntities = [];
    for (const e of this.documentSet.sentimentBarEntities) {
      firstSentimentBarEntities.push({
        name: e.mostOccurringHeadToken,
        value: e.politicalOrientationSentiments[POLITICAL_ORIENTATIONS.LEFT.key],
      });
      secondSentimentBarEntities.push({
        name: e.mostOccurringHeadToken,
        value: e.politicalOrientationSentiments[POLITICAL_ORIENTATIONS.MIDDLE.key],
      });
      thirdSentimentBarEntities.push({
        name: e.mostOccurringHeadToken,
        value: e.politicalOrientationSentiments[POLITICAL_ORIENTATIONS.RIGHT.key],
      });
    }
    this.setDocuments({
      mainEntity: this.documentSet.mainEntity,
      mainDocument: this.documentSet.mainDocument,
      firstSideDocuments: documents[POLITICAL_ORIENTATIONS.LEFT.key],
      secondSideDocuments: documents[POLITICAL_ORIENTATIONS.MIDDLE.key],
      thirdSideDocuments: documents[POLITICAL_ORIENTATIONS.RIGHT.key],
      firstSideTitle: POLITICAL_ORIENTATIONS.LEFT.articlePreviewTitle,
      secondSideTitle: POLITICAL_ORIENTATIONS.MIDDLE.articlePreviewTitle,
      thirdSideTitle: POLITICAL_ORIENTATIONS.RIGHT.articlePreviewTitle,
      mainSummary: this.documentSet.summaries.whole,
      firstSideSummary: this.documentSet.summaries.sides[POLITICAL_ORIENTATIONS.LEFT.keyLower],
      secondSideSummary: this.documentSet.summaries.sides[POLITICAL_ORIENTATIONS.MIDDLE.keyLower],
      thirdSideSummary: this.documentSet.summaries.sides[POLITICAL_ORIENTATIONS.RIGHT.keyLower],
      firstSideEntitiesPolarities: firstSentimentBarEntities,
      secondSideEntitiesPolarities: secondSentimentBarEntities,
      thirdSideEntitiesPolarities: thirdSentimentBarEntities,
    });
  },
  methods: {
    setDocuments(threeDocumentsData) {
      this.documents = new ThreeSidesDocuments(threeDocumentsData);
    },
    defaultGroupingInstructions() {
      const { overviewUseSummary } = this.$store.state.configuration;
      return groupingInstructions(
        ['all', 'showcase'].includes(overviewUseSummary),
      );
    },
  },
};

</script>
