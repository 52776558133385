<template>
  <div>
    <article>
      <h2>
        <router-link
            :to="destination"
         >
          {{ title }}
        </router-link>
        <div
          v-if="$store.state.configuration.overviewShowArticleCount && !onlyHeadline"
          class="article-count-visualization">
          <div
            :aria-label="groupTooltip"
            data-balloon-pos="up"
            ref="articleCountVisualization"></div>
        </div>
      </h2>
      <!--
        <div
          class="bias-categories"
          v-if="
            $store.state.configuration.overviewShowBiasCategories !== 'off'
            || $store.state.configuration.overviewShowPolarity">
          portraying ‘{{ mainEntity.shortRepresentative }}’ as
          <a
            v-if="$store.state.configuration.overviewShowPolarity">
            <br />
            <span
              :class="{
                'positive': sentiment[1] === 1,
                'negative': sentiment[1] === -1,
                }">{{ sentiment[0] }}</span>
          </a>
        </div>
      -->

      <router-link
        :to="destination"
        v-if="document.imageUrl"
        class="h-32 bg-cover bg-no-repeat bg-center block"
        :style="`background-image: url('${document.imageUrl}')`">
      </router-link>
      <h3 class="article-headline" v-if="!noHeadline"><!--
      --><router-link
          :to="destination"
         ><!--
         -->{{ document.title ? document.title : '[MISSING TITLE]' }}<!--
         --></router-link>
         <EntityPolarityBarChart
           v-if="showArticleBarChartPolarities"
           class="inline-block pl-2 pr-1"
           :barChartPolarities="document.sentimentBarData"
           :visualizeLarge="false" />
         <Tags
           :showPoliticalOrientation="!noPoliticalSideTag &&
             $store.state.configuration.overviewShowPoliticalSideTags"
           :showPolarity="!noPolarityTag &&
             $store.state.configuration.overviewShowPolarityTags"
           :showCluster="!noClusterTag &&
             $store.state.configuration.overviewShowClusterTags"
           :showAnonymous="!noAnonymousTag &&
             $store.state.configuration.overviewShowAnonymousTags"
           :document="document"
          />
      </h3>
      <p v-if="leadParagraph && !onlyHeadline && !smallPreview" class="preview-paragraph"
         :class="{ 'mt-0 pt-0': noHeadline }"><!--
      --><router-link
        :to="destination">{{ leadParagraph }} [&mldr;]</router-link><!--
      --></p>
      <EntityPolarityBarChart v-if="barChartPolarities"
                              class="polarity-bar-chart"
                              :barChartPolarities="barChartPolarities" />
    </article>
  </div>
</template>

<script>
import Tags from '@/components/Tags.vue';
import EntityPolarityBarChart from '@/components/EntityPolarityBarChart.vue';
import POLARITIES from '@/libraries/Polarities.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import ArticleCountVisualization from '@/libraries/ArticleCountVisualization.js';

export default {
  name: 'ArticlePreview',
  POLITICAL_ORIENTATIONS,
  POLARITIES,
  components: {
    Tags,
    EntityPolarityBarChart,
  },
  props: {
    documentSetTopic: String,
    title: String,
    document: Object,
    to: [String, Object],
    mainEntity: Object,
    leadParagraph: String,
    entityPolarity: String,
    articleCount: Number,
    politicalSide: String,
    articleCountData: Object,
    hightlightArticleCount: String,
    dimArticleCount: String,
    noHeadline: {
      type: Boolean,
      default: false,
    },
    noPoliticalSideTag: {
      type: Boolean,
      default: false,
    },
    noPolarityTag: {
      type: Boolean,
      default: false,
    },
    noClusterTag: {
      type: Boolean,
      default: false,
    },
    noAnonymousTag: {
      type: Boolean,
      default: false,
    },
    onlyHeadline: {
      type: Boolean,
      default: false,
    },
    barChartPolarities: {
      type: [Array, null],
      default: null,
    },
    showArticleBarChartPolarities: {
      type: Boolean,
      default: false,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reloadNextTickDone: true,
      useUrl: process.env.VUE_APP_LINK_TO_ARTICLE_VIEW === '0',
    };
  },
  computed: {
    root() {
      return this.$refs.articleCountVisualization;
    },
    groupTooltip() {
      let others = 0;
      let self = 0;
      for (const [name, value] of Object.entries(this.articleCountData)) {
        if (name === this.hightlightArticleCount) {
          self = value;
        } else {
          others += value;
        }
      }
      return `This group includes ${self} of ${self + others} articles.`;
    },
    destination() {
      const {
        documentSetTopic, smallPreview, to,
      } = this.$props;
      return this.useUrl && !smallPreview
        ? { name: 'preview', params: { url: this.document.url, id: documentSetTopic } }
        : to;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadVisualization();
    });
  },
  methods: {
    loadVisualization() {
      new ArticleCountVisualization(
        this.root,
        this.articleCountData,
        [this.hightlightArticleCount],
      ).visualize();
    },
    removeVisualization() {
      let child = this.root.lastElementChild;
      while (child) {
        this.root.removeChild(child);
        child = this.root.lastElementChild;
      }
    },
    reloadVisualization() {
      this.removeVisualization();
      this.loadVisualization();
    },
    reloadNextTick() {
      this.reloadNextTickDone = false;
      this.$nextTick(() => {
        if (!this.reloadNextTick) {
          this.reloadVisualization();
        }
        this.reloadNextTickDone = true;
      });
    },
  },
  watch: {
    documentPolarity() {
      this.reloadNextTick();
    },
    mainDocumentPolarity() {
      this.reloadNextTick();
    },
  },
};
</script>
