import POLARITIES from '@/libraries/Polarities.js';
import { DEFAULT_CLASS } from '@/libraries/ThreeSidesDocuments.js';

const NUM_TO_TEXT = {
  [undefined]: '',
  0: '',
  1: 'one ',
  2: 'two ',
  3: 'three ',
  4: 'four ',
  5: 'five ',
  6: 'six ',
  7: 'seven ',
  8: 'eight ',
  9: 'nine ',
  10: 'ten ',
};

function groupingInstructions(useSummary, numPerspectives) {
  const numPerspectivesText = NUM_TO_TEXT[numPerspectives];

  let belowText = 'Below, you see for each group its most representative article.';
  if (useSummary) {
    belowText = 'Below, you see for each group a summary.';
  }

  return {
    name: 'AnonymousGroupingInstructions',
    template: `<span>
                   When reviewing media coverage on the given news topic, we identified the
                   following ${numPerspectivesText}main perspectives. For each article, we then
                   determined its main perspective. ${belowText}
               </span>`,
  };
}

function groupingInstructionsSpecific(useSummary) {
  let belowText = 'Below, you see for each group its most representative article.';
  if (useSummary) {
    belowText = 'Below, you see for each group a summary.';
  }

  return {
    name: 'AnonymousGroupingInstructions',
    template: `<span>
                 Our automated system randomly assigned each article to either of the following
                 groups. Below, you see for each group its most representative article. ${belowText}
               </span>`,
  };
}

const threeSidesDocumentsSettings = {
  firstSidePolarity: POLARITIES.NEUTRAL,
  secondSidePolarity: POLARITIES.NEUTRAL,
  thirdSidePolarity: POLARITIES.NEUTRAL,
  firstSideClass: 'perspective-1',
  secondSideClass: 'perspective-2',
  thirdSideClass: 'perspective-3',
};

class NameGenerator {
  constructor() {
    this.counter = 0;
    this.groupNames = {};
  }

  name(identifier, forceIdentifierAsName = true) {
    let name = this.groupNames[identifier];
    if (name !== undefined) {
      return name;
    }
    this.counter += 1;
    if (forceIdentifierAsName) {
      name = `Perspective ${identifier}`;
    } else {
      name = `Perspective ${this.counter}`;
    }
    this.groupNames[identifier] = name;
    return name;
  }

  articleTooltipText(identifier) {
    return `This article represents ${this.name(identifier)}.`;
  }

  labelClass(identifier) {
    return `label-perspective label-${this.name(identifier).replaceAll(' ', '-').toLowerCase()}`;
  }
}

const exampleGenerator = new NameGenerator();
const examples = [1, 2, 3].map(x => ({
  name: exampleGenerator.name(x),
  articleTooltipText: exampleGenerator.articleTooltipText(x),
  labelClass: exampleGenerator.labelClass(x),
}));

export {
  groupingInstructions,
  groupingInstructionsSpecific,
  threeSidesDocumentsSettings,
  NameGenerator,
  examples,
};
