function orientations() {
  return {
    LEFT: {
      key: 'LEFT',
      keyLower: 'left',
      text: 'left',
      labelClass: 'label-left',
      articlePreviewTitle: 'From the left',
      articleTooltipText: 'Article\'s political orientation: left.',
      anonymousId: 1,
    },
    MIDDLE: {
      key: 'MIDDLE',
      keyLower: 'middle',
      text: 'center',
      labelClass: 'label-middle',
      articlePreviewTitle: 'From the center',
      articleTooltipText: 'Article\'s political orientation: center.',
      anonymousId: 2,
    },
    RIGHT: {
      key: 'RIGHT',
      keyLower: 'right',
      text: 'right',
      labelClass: 'label-right',
      articlePreviewTitle: 'From the right',
      articleTooltipText: 'Article\'s political orientation: right.',
      anonymousId: 3,
    },
  };
}

export default Object.freeze(orientations());
export { orientations };
