class NameGenerator {
  constructor() {
    this.counter = 0;
    this.groupNames = {};
  }

  name(identifier) {
    let name = this.groupNames[identifier];
    if (name !== undefined) {
      return name;
    }
    this.counter += 1;
    name = `Cluster ${this.counter}`;
    this.groupNames[identifier] = name;
    return name;
  }

  articleTooltipText(identifier) {
    return `[TODO Clusters.js:19] ${this.name(identifier)}.`;
  }
}

export default NameGenerator;
