<template>
  <div>
    <ThreeSides
      :identifier="identifier"
      :documents="documents"
      :documentSet="documentSet"
      :instructionBox="{}"
      :groupingInstructions="groupingInstructions"
      :previewNoClusterTags="previewNoClusterTags"
      :previewNoAnonymousTags="previewNoAnonymousTags"
      :onlyPreviewHeadlines="onlyPreviewHeadlines"
      :file="file"
      :small-preview="smallPreview"
      />
  </div>
</template>

<script>
import ThreeSides from '@/components/overview/ThreeSides.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import DocumentParser from '@/libraries/DocumentParser.js';
import { groupingInstructions } from '@/libraries/AnonymousViewsHelpers.js';

function groupingInstructionsGenerator(onlyPerson, forSummary) {
  // eslint-disable-next-line no-param-reassign
  onlyPerson = onlyPerson || false;
  // eslint-disable-next-line no-param-reassign
  forSummary = forSummary || false;
  let text = '';
  if (onlyPerson) {
    text += `Our automated system assigned them to either of the following groups depending on how
             the article portrays the persons mentioned in the news event. For example, articles
             reporting positively (or negatively) on the same person(s) will be placed in the same
             group. `;
  } else {
    text += `Our automated system assigned them to either of the following groups depending on how
             the article portrays persons, groups of persons, institutions, cities or countries. For
             example, articles highlighting the same aspects will be placed in the same group. `;
  }
  if (forSummary) {
    text += 'Below, you see for each group a summary.';
  } else {
    text += 'Below, you see for each group its most representative article.';
  }
  return {
    name: 'ClusteringGroupingInstructions',
    template: `<span>${text}</span>`,
  };
}

export default {
  name: 'AnonymousClustering',
  components: {
    ThreeSides,
  },
  data() {
    return {
      previewNoClusterTags: true,
      previewNoAnonymousTags: false,
      documents: null,
      groupingInstructions,
    };
  },
  props: {
    documentSet: Object,
    identifier: String,
    onlyPreviewHeadlines: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      required: true,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const {
      overviewClusterType,
      overviewClusterAlgorithm,
    } = this.$store.state.configuration;

    const clusters = this.documentSet.polarityClusters;
    const firstSentimentBarEntities = [];
    const secondSentimentBarEntities = [];
    const thirdSentimentBarEntities = [];
    for (const e of this.documentSet.sentimentBarEntities) {
      firstSentimentBarEntities.push({
        name: e.mostOccurringHeadToken,
        value: e.polarityClusterAverageSentiment[0],
      });
      if (e.polarityClusterAverageSentiment[1] !== undefined) {
        secondSentimentBarEntities.push({
          name: e.mostOccurringHeadToken,
          value: e.polarityClusterAverageSentiment[1],
        });
      }
      if (e.polarityClusterAverageSentiment[2] !== undefined) {
        thirdSentimentBarEntities.push({
          name: e.mostOccurringHeadToken,
          value: e.polarityClusterAverageSentiment[2],
        });
      }
    }
    this.setDocuments({
      mainEntity: this.documentSet.mainEntity,
      mainDocument: this.documentSet.mainDocument,
      firstSideDocuments: clusters[0].documents,
      secondSideDocuments: clusters[1] ? clusters[1].documents : [],
      thirdSideDocuments: clusters[2] ? clusters[2].documents : [],
      firstSideTitle: clusters[0].name,
      secondSideTitle: clusters[1] ? clusters[1].name : [],
      thirdSideTitle: clusters[2] ? clusters[2].name : [],
      firstSideSummary: clusters[0].summary,
      secondSideSummary: clusters[1] ? clusters[1].summary : '',
      thirdSideSummary: clusters[2] ? clusters[2].summary : '',
      firstSideEntitiesPolarities: firstSentimentBarEntities,
      secondSideEntitiesPolarities: secondSentimentBarEntities,
      thirdSideEntitiesPolarities: thirdSentimentBarEntities,
      sentimentBarEntities: this.documentSet.sentimentBarEntities,
    });
  },
  methods: {
    setDocuments(threeDocumentsData) {
      this.documents = new ThreeSidesDocuments(threeDocumentsData);
    },
    defaultGroupingInstructions() {
      const { overviewExplanation, overviewUseSummary } = this.$store.state.configuration;
      return groupingInstructionsGenerator(
        overviewExplanation.endsWith('person'),
        ['all', 'showcase'].includes(overviewUseSummary),
      );
    },
  },
};

</script>
