import _ from 'lodash';
import seedrandom from 'seedrandom';
import POLARITIES, { polarities } from '@/libraries/Polarities.js';
import POLITICAL_ORIENTATIONS, { orientations } from '@/libraries/PoliticalOrientations.js';
import { NameGenerator as AnonymousNameGenerator }
  from '@/libraries/AnonymousViewsHelpers.js';
import ClusterNameGenerator from '@/libraries/Clusters.js';

const OFFSET_TEXT = 'text';

const CLUSTER_TYPE_KEY = {
  average: 'average',
  local_normalized: 'localNormalized',
  global_normalized: 'globalNormalized',
  localNormalized: 'localNormalized',
  globalNormalized: 'globalNormalized',
};

const CLUSTER_ALGORITHM_KEY = {
  k_means: 'kMeans',
  affinity_propagation: 'affinityPropagation',
  kMeans: 'kMeans',
  affinityPropagation: 'affinityPropagation',
};

const CLUSTER_TYPES = ['average', 'local_normalized', 'global_normalized'];
const UNDEFINED_CLUSTER_VALUE = null;

/* eslint-disable no-param-reassign */
class Helpers {
  constructor(
    documentSet,
    configuration,
    clusterNameGenerator,
    anonymousNameGenerator,
  ) {
    this.documentSet = documentSet;
    this.configuration = configuration;
    this.clusterNameGenerator = clusterNameGenerator;
    this.anonymousNameGenerator = anonymousNameGenerator;
    this.absoluteMainEntityPolarityPerDocument = null;
  }

  c(key) {
    const suffix = key.charAt(0).toUpperCase() + key.slice(1);
    return this.configuration[`overview${suffix}`]
      || this.configuration[`article${suffix}`];
  }

  /**
   * Get the start position of the main text (i.e., not description, not title) of a documents
   * fulltext. (The fulltext is a string which includes title + description + main test).
   */
  static textOffset(document) {
    for (const offsetData of document.fulltextOffsets) {
      if (offsetData.attribute === OFFSET_TEXT) {
        return offsetData.offset;
      }
    }
    return 0;
  }

  /**
   * Add `.mainEntity` to documentSet.
   */
  parseMainEntity() {
    const selectBy = this.c('mainEntitySelection');
    if (this.documentSet.entities === undefined || selectBy === undefined) {
      return;
    }
    const id = this.documentSet[(selectBy === 'appearances'
      ? 'mainEntityIdByAppearances' : 'mainEntityIdByMentionScore')];
    if (id === undefined) {
      return;
    }
    this.documentSet.mainEntity = this.documentSet.entities[id];
  }

  /**
   * Add `.mainDocument` to documentSet.
   */
  parseMainDocument() {
    if (!this.documentSet.documents.every(d => d.representativeness !== undefined)) {
      return;
    }
    this.documentSet.mainDocument = this.documentSet.documents
      .reduce((a, b) => (a.representativeness > b.representativeness ? a : b));
  }

  addClusters() {
    const vectors = this.c('clusterVectors', this.configuration);
    // console.log('vectors', vectors);
    const type = CLUSTER_TYPE_KEY[this.c('clusterType', this.configuration)];
    const algorithm = CLUSTER_ALGORITHM_KEY[this.c('clusterAlgorithm', this.configuration)];
    let gqlSummaries = [];
    if (this.documentSet.summaries) {
      gqlSummaries = this.documentSet.summaries[vectors][algorithm][type];
    } else {
      console.warn('No summaries delivered.');
    }
    const summaries = {};
    for (const { clusterName, summary } of gqlSummaries) {
      summaries[clusterName] = summary;
    }
    const clusters = {};
    for (const doc of this.documentSet.documents) {
      const id = doc[vectors][type][algorithm];
      if (clusters[id] === undefined) {
        clusters[id] = [[doc], id, summaries[id]];
      } else {
        clusters[id][0].push(doc);
      }
    }
    this.documentSet.polarityClusters = Object.values(clusters)
      .sort((x, y) => y[0].length - x[0].length)
      .map((documentsData, index) => ({
        name: this.clusterNameGenerator.name(index),
        index,
        documents: documentsData[0],
        label: documentsData[1],
        summary: documentsData[2],
        articleTooltipText: this.clusterNameGenerator.articleTooltipText(index),
      }));

    for (const {
      name, articleTooltipText, index, documents,
    } of this.documentSet.polarityClusters) {
      for (const doc of documents) {
        doc.polarityClusterData = { name, articleTooltipText, index };
      }
    }
  }

  /**
   * Add `.leadParagraph` to each document in documentSet.documents.
   * It is the description if available, otherwise the first sentence.
   */
  addLeadParagraphs() {
    for (const document of this.documentSet.documents) {
      let lead = document.description;
      if (!lead) {
        let sentence;
        const offset = Helpers.textOffset(document);
        for (const { beginChar, endChar } of document.sentences) {
          if (beginChar >= offset) {
            lead = document.fulltext.slice(beginChar, endChar);
            break;
          }
        }
      }
      if (!lead) {
        lead = '';
      }
      document.leadParagraph = lead;
    }
  }

  calculateAbsoluteMainEntityPolarityPerDocument() {
    this.absoluteMainEntityPolarityPerDocument = Object.fromEntries(
      this.documentSet.documents.map(
        d => [
          d.index,
          Helpers.averageAbsoluteEntityPolarity(this.documentSet.mainEntity, [d]),
        ],
      ),
    );
  }

  static averageAbsoluteEntityPolarity(entity, documents = false) {
    const allowedIndices = documents ? documents.map(d => d.index) : documents;
    let value = 0;
    let counter = 0;
    for (const member of entity.members) {
      if ((allowedIndices && !allowedIndices.includes(member.document.index))
          || !member.sentiment) {
        continue;
      }
      const { sentiment, probability } = member.sentiment;
      if (sentiment && POLARITIES[sentiment].value !== undefined) {
        value += POLARITIES[sentiment].value * probability;
        counter += probability;
      }
    }
    if (counter === 0) {
      return 0;
    }
    return value / counter;
  }

  addRandomPolarities(polaritySeed) {
    for (const doc of this.documentSet.documents) {
      const seed = `${polaritySeed}_${this.documentSet.mainEntity.representative}_${doc.index}`;
      const rng = seedrandom(seed);
      doc.mainEntityPolarityValue = rng() * 2 - 1;
    }
  }

  addAbsolutePolarities() {
    for (const doc of this.documentSet.documents) {
      doc.mainEntityPolarityValue = this.absoluteMainEntityPolarityPerDocument[doc.index];
    }
  }

  addRelativePolarities() {
    const all = Object.values(this.absoluteMainEntityPolarityPerDocument);
    const min = Math.min(...all);
    const max = Math.max(...all);
    for (const document of this.documentSet.documents) {
      const absValue = this.absoluteMainEntityPolarityPerDocument[document.index];
      document.mainEntityPolarityValue = ((absValue - min) / (max - min)) * 2 - 1;
    }
  }

  addDocumentSetPolarity(setPolarities) {
    this.documentSet.mainEntityPolarityValue = this.documentSet.documents
      .map(d => d.mainEntityPolarityValue)
      .reduce((d1, d2) => d1 + d2, 0) / this.documentSet.documents.length;
    this.documentSet.mainEntityPolarity = setPolarities.copyFromValue(
      this.documentSet.mainEntityPolarityValue,
      this.documentSet.mainEntity.shortRepresentative,
    );
  }

  addDocumentPolarity(setPolarities) {
    // decrease anonymous id so we'll always start at 1 without gap.
    const polarityArray = setPolarities.all()
      .sort((p1, p2) => p1.anonymousId - p2.anonymousId);
    const usedPolarities = new Set(this.documentSet.documents.map(
      d => setPolarities.fromValue(d.mainEntityPolarityValue),
    ));
    let decreaser = 0;
    for (const polarity of polarityArray) {
      polarity.anonymousId -= decreaser;
      if (!usedPolarities.has(polarity)) {
        decreaser += 1;
        polarity.anonymousId = -1;
      }
    }

    // actually add the polarities
    for (const doc of this.documentSet.documents) {
      doc.mainEntityPolarity = setPolarities.copyFromValue(
        doc.mainEntityPolarityValue,
        this.documentSet.mainEntity.shortRepresentative,
      );
    }
  }

  addPolarity(seed = null) {
    if (!this.documentSet.mainEntity) {
      return;
    }
    const method = this.c('polarityMethod', this.configuration);
    if (method === 'random') {
      if (seed === null) {
        seed = this.c('polaritySeed', this.configuration);
      }
      this.addRandomPolarities(seed);
    } else {
      this.calculateAbsoluteMainEntityPolarityPerDocument();
      if (method === 'relative') {
        this.addRelativePolarities();
      } else {
        this.addAbsolutePolarities();
      }
    }
    const setPolarities = polarities();
    this.addDocumentPolarity(setPolarities);
    this.addDocumentSetPolarity(setPolarities);
  }

  addPoliticalOrientation() {
    // decrease anonymous id so we'll always start at 1 without gap.

    // backend generates random political orientations
    const politicalOrientations = orientations();
    const usedOrientations = new Set(this.documentSet.documents.map(
      d => politicalOrientations[d.politicalBias.side],
    ));
    let decreaser = 0;
    for (const orientation of Object.values(politicalOrientations)) {
      orientation.anonymousId -= decreaser;
      if (!usedOrientations.has(orientation)) {
        decreaser += 1;
        orientation.anonymousId = -1;
      }
    }

    // add the actual orientation
    for (const document of this.documentSet.documents) {
      const { side } = document.politicalBias;
      document.politicalOrientation = politicalOrientations[side];
    }
  }

  addAnonymousPerspectives() {
    const tagType = this.c('anonymousTagType', this.configuration);
    for (const doc of this.documentSet.documents) {
      let identifier;
      if (tagType === 'cluster') {
        identifier = doc.polarityClusterData.index + 1;
      } else if (tagType === 'political_side') {
        identifier = doc.politicalOrientation.anonymousId;
      } else if (tagType === 'polarity') {
        identifier = doc.mainEntityPolarity.anonymousId;
      }
      doc.anonymousGroup = {
        name: this.anonymousNameGenerator.name(identifier),
        articleTooltipText: this.anonymousNameGenerator.articleTooltipText(identifier),
        labelClass: this.anonymousNameGenerator.labelClass(identifier),
      };
    }
  }

  parseSentimentBarEntities() {
    const method = this.c('sentimentBarMethod', this.configuration);
    const d = this.documentSet;
    if (method === 'mention_score') {
      d.sentimentBarEntities = d.entityIdsByMentionScore.slice(0, 3).map(x => d.entities[x]);
    } else {
      d.sentimentBarEntities = d.entities.slice(0, 3);
    }
  }

  parseClusterValues() {
    const type = CLUSTER_TYPE_KEY[this.c('clusterType', this.configuration)];
    for (const e of this.documentSet.entities) {
      const clusterValues = {};
      const orientationValues = {
        [POLITICAL_ORIENTATIONS.LEFT.key]: 0,
        [POLITICAL_ORIENTATIONS.MIDDLE.key]: 0,
        [POLITICAL_ORIENTATIONS.RIGHT.key]: 0,
      };
      const orientationCount = {
        [POLITICAL_ORIENTATIONS.LEFT.key]: 0,
        [POLITICAL_ORIENTATIONS.MIDDLE.key]: 0,
        [POLITICAL_ORIENTATIONS.RIGHT.key]: 0,
      };
      const documentSentiments = {};
      for (const { documents, index } of this.documentSet.polarityClusters) {
        const clusterIndex = index;
        let count = 0;
        clusterValues[clusterIndex] = 0;
        const docIndices = new Set(documents.map(d => d.index));
        for (const { documentIndex, clusterType, value } of e.personPolarityClusterValues) {
          if (CLUSTER_TYPE_KEY[clusterType] === type) {
            documentSentiments[documentIndex] = value;
            if (this.documentSet.documents[documentIndex].politicalOrientation) {
              const orientation = this.documentSet.documents[documentIndex]
                .politicalOrientation.key;
              orientationValues[orientation] += value;
              orientationCount[orientation] += 1;
            } else {
              console.warn('No political orientation provided');
            }
            if (docIndices.has(documentIndex)) {
              count += 1;
              clusterValues[clusterIndex] += value;
            }
          }
        }
        clusterValues[clusterIndex] /= count;
      }
      orientationValues[POLITICAL_ORIENTATIONS.LEFT.key]
        /= orientationCount[POLITICAL_ORIENTATIONS.LEFT.key];
      orientationValues[POLITICAL_ORIENTATIONS.MIDDLE.key]
        /= orientationCount[POLITICAL_ORIENTATIONS.LEFT.key];
      orientationValues[POLITICAL_ORIENTATIONS.RIGHT.key]
        /= orientationCount[POLITICAL_ORIENTATIONS.LEFT.key];
      e.polarityClusterAverageSentiment = clusterValues;
      e.politicalOrientationSentiments = orientationValues;
      e.documentSentiments = documentSentiments;
    }
  }

  parseDocumentSentimentBarData() {
    for (const d of this.documentSet.documents) {
      d.sentimentBarData = [];
      for (const e of this.documentSet.sentimentBarEntities) {
        d.sentimentBarData.push({
          name: e.mostOccurringHeadToken,
          value: e.documentSentiments[d.index],
          count: 0,
        });
      }
    }
  }
}
/* eslint-enable no-param-reassign */

export default {

  /**
   * Parse a documentSet to add additional information
   * (e.g. after it was loaded by apollo)
   */
  parseDocumentSet(documentSet, configuration, seed = null) {
    // console.log('parseDocumentSet', documentSet);
    const helpers = new Helpers(
      documentSet,
      configuration,
      new ClusterNameGenerator(),
      new AnonymousNameGenerator(),
    );
    helpers.parseMainEntity();
    helpers.parseMainDocument();
    helpers.addLeadParagraphs();
    helpers.addClusters();
    helpers.addPolarity(seed);
    try {
      helpers.addPoliticalOrientation();
    } catch (e) {
      if (!(e instanceof TypeError)) {
        throw e;
      }
      console.warn('No political orientation delivered?', e);
    }
    helpers.addAnonymousPerspectives();
    helpers.parseSentimentBarEntities();
    helpers.parseClusterValues();
    helpers.parseDocumentSentimentBarData();
    // console.log('DocumentParser.js:parseDocumentSet', documentSet);
    return documentSet;
  },

  /**
   * Parse multiple documentSets to add additional information
   * (e.g. after they were loaded by apollo)
   */
  parseDocumentSets(documentSets, configuration, seed = null) {
    for (const identifierDocumentSet of documentSets) {
      this.parseDocumentSet(identifierDocumentSet.documentSet, configuration, seed);
    }
    return documentSets;
  },
};
