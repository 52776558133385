<template>
  <section>
    <InstructionBox
      v-if="!onlyPreviewHeadlines && !hideInstructions"
      :explainPoliticalSideTags="$store.state.configuration.overviewShowPoliticalSideTags"
      :explainPolarityTags="$store.state.configuration.overviewShowPolarityTags"
      :explainAnonymousTags="$store.state.configuration.overviewShowAnonymousTags"
      :mainEntityRepresentative="documents.mainEntity.shortRepresentative"/>
    <div class="flex">
      <div class="w-1/4 flex-col box-border pt-3 pb-2 pr-2" v-if="documents.mainDocument.imageUrl">
        <router-link
          :to="mainRoute"
          class="w-full h-full bg-cover bg-center block"
          :style="`background-image: url('${documents.mainDocument.imageUrl}')`"
        ></router-link>
      </div>
      <div class="flex-col" :class="{'w-3/4': documents.mainDocument.imageUrl}">
        <h1 class="article-headline" v-if="!onlyPreviewHeadlines && !summaryInMain">
          <router-link
            :to="mainRoute">
            {{ documents.mainDocument.title ? documents.mainDocument.title : '[MISSING TITLE]' }}
          </router-link>
          <EntityPolarityBarChart
            v-if="$store.state.configuration.overviewSentimentBars === 'show'"
            class="inline-block align-bottom pl-1"
            style="bottom: -5px;"
            :barChartPolarities="documents.mainDocument.sentimentBarData"
            :visualizeLarge="false"/>
          <Tags
            :showPoliticalOrientation="$store.state.configuration.overviewShowPoliticalSideTags"
            :showPolarity="$store.state.configuration.overviewShowPolarityTags"
            :showCluster="$store.state.configuration.overviewShowClusterTags"
            :showAnonymous="$store.state.configuration.overviewShowAnonymousTags"
            :document="documents.mainDocument"
          />
        </h1>
        <p v-if="!onlyPreviewHeadlines">
          <router-link
            :to="mainRoute">
            {{ mainText }} [&mldr;]
          </router-link>
        </p>
      </div>
    </div>

    <div class="instruction-box"
         v-if="!onlyPreviewHeadlines && groupingInstructions && !hideInstructions">
      <component
        :is="groupingInstructions"
        :mainEntity="documents.mainEntity"
      />
    </div>

    <div class="article-showcase" :class="articleShowCaseClass"><!--most-frequent-actor/all-sides-->
      <ArticlePreview
        class="article-preview w-full md:w-1/3"
        :class="documents.firstSideClass"
        v-if="documents.firstSideMainDocument"
        :document="documents.firstSideMainDocument"
        :score="documents.firstSideScore"
        :lead-paragraph="firstText"
        :document-set-topic="documentSet.topic"
        :title="documents.firstSideTitle"
        :mainEntity="documents.mainEntity"
        :articleCount="documents.firstSideCount"
        :to="article1Route"
        :noPoliticalSideTag="previewNoPoliticalSideTags"
        :noPolarityTag="previewNoPolarityTags"
        :noClusterTag="previewNoClusterTags"
        :noAnonymousTag="previewNoAnonymousTags"
        :onlyHeadline="onlyPreviewHeadlines"
        :noHeadline="summariesInShowcase"
        :articleCountData="countData"
        :hightlightArticleCount="documents.firstSideTitle"
        :dimArticleCount="TEXT_ARTICLE_COUNT_OTHER"
        :showArticleBarChartPolarities="$store.state.configuration.overviewSentimentBars === 'show'"
        :barChartPolarities="$store.state.configuration.overviewSentimentBars === 'hide'
          ? null : documents.firstSideEntitiesPolarities"
        :smallPreview="smallPreview"
      />
      <ArticlePreview
        class="article-preview w-full md:w-1/3"
        :class="documents.secondSideClass"
        :document-set-topic="documentSet.topic"
        v-if="documents.secondSideMainDocument"
        :document="documents.secondSideMainDocument"
        :score="documents.secondSideScore"
        :lead-paragraph="secondText"
        :title="documents.secondSideTitle"
        :mainEntity="documents.mainEntity"
        :articleCount="documents.secondSideCount"
        :to="article2Route"
        :noPoliticalSideTag="previewNoPoliticalSideTags"
        :noPolarityTag="previewNoPolarityTags"
        :noClusterTag="previewNoClusterTags"
        :noAnonymousTag="previewNoAnonymousTags"
        :onlyHeadline="onlyPreviewHeadlines"
        :noHeadline="summariesInShowcase"
        :articleCountData="countData"
        :hightlightArticleCount="documents.secondSideTitle"
        :dimArticleCount="TEXT_ARTICLE_COUNT_OTHER"
        :showArticleBarChartPolarities="$store.state.configuration.overviewSentimentBars === 'show'"
        :barChartPolarities="$store.state.configuration.overviewSentimentBars === 'hide'
          ? null : documents.secondSideEntitiesPolarities"
        :smallPreview="smallPreview"
      />
      <ArticlePreview
        class="article-preview w-full md:w-1/3"
        :class="documents.thirdSideClass"
        :document-set-topic="documentSet.topic"
        v-if="documents.thirdSideMainDocument"
        :document="documents.thirdSideMainDocument"
        :score="documents.thirdSideScore"
        :lead-paragraph="thirdText"
        :title="documents.thirdSideTitle"
        :mainEntity="documents.mainEntity"
        :articleCount="documents.thirdSideCount"
        :to="article3Route"
        :noPoliticalSideTag="previewNoPoliticalSideTags"
        :noPolarityTag="previewNoPolarityTags"
        :noClusterTag="previewNoClusterTags"
        :noAnonymousTag="previewNoAnonymousTags"
        :onlyHeadline="onlyPreviewHeadlines"
        :noHeadline="summariesInShowcase"
        :articleCountData="countData"
        :hightlightArticleCount="documents.thirdSideTitle"
        :dimArticleCount="TEXT_ARTICLE_COUNT_OTHER"
        :showArticleBarChartPolarities="$store.state.configuration.overviewSentimentBars === 'show'"
        :barChartPolarities="$store.state.configuration.overviewSentimentBars === 'hide'
          ? null : documents.thirdSideEntitiesPolarities"
        :smallPreview="smallPreview"
      />
    </div>
    <h2 class="further-articles" v-if="!onlyPreviewHeadlines && !smallPreview">Further articles</h2>
    <HeadlineList
      class="w-full"
      v-if="documents.nonMainDocuments.length && !onlyPreviewHeadlines && !smallPreview"
      :displayDocuments="documents.nonMainDocuments"
      :documentSet="documentSet"
      :identifier="identifier"
      :leadParagraphs="$store.state.configuration.overviewShowOtherLeadParagraphs"
      :polarityTags="$store.state.configuration.overviewShowPolarityTags"
      :politicalSideTags="$store.state.configuration.overviewShowPoliticalSideTags"
      :clusterTags="$store.state.configuration.overviewShowClusterTags"
      :anonymousTags="$store.state.configuration.overviewShowAnonymousTags"
      :showArticleBarChartPolarities="$store.state.configuration.overviewSentimentBars === 'show'"
      :fullCoverage="false"
      :max="6"
      :file="file"
    />
  </section>
</template>

<script>
import InstructionBox from '@/components/overview/InstructionBox.vue';
import DocumentParser from '@/libraries/DocumentParser.js';
import ArticlePreview from '@/components/overview/ArticlePreview.vue';
import Tags from '@/components/Tags.vue';
import HeadlineList from '@/components/overview/HeadlineList.vue';
import EntityPolarityBarChart from '@/components/EntityPolarityBarChart.vue';
import ThreeSidesDocuments from '@/libraries/ThreeSidesDocuments.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import POLARITIES from '@/libraries/Polarities.js';

const TEXT_ARTICLE_COUNT_OTHER = 'other';

export default {
  name: 'ThreeSides',
  data() {
    return {
      TEXT_ARTICLE_COUNT_OTHER,
      useUrl: process.env.VUE_APP_LINK_TO_ARTICLE_VIEW === '0',
    };
  },
  components: {
    InstructionBox,
    ArticlePreview,
    HeadlineList,
    EntityPolarityBarChart,
    Tags,
  },
  POLITICAL_ORIENTATIONS,
  DocumentParser,
  props: {
    file: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
    documentSet: {
      type: Object,
      required: true,
    },
    documents: {
      type: ThreeSidesDocuments,
      required: true,
    },
    articleShowCaseClass: {
      type: String,
      default: '',
    },
    instructionBox: {
      type: Object,
      required: true,
    },
    groupingInstructions: {
      type: Object,
    },
    previewNoPoliticalSideTags: {
      type: Boolean,
      default: false,
    },
    previewNoPolarityTags: {
      type: Boolean,
      default: false,
    },
    previewNoClusterTags: {
      type: Boolean,
      default: false,
    },
    previewNoAnonymousTags: {
      type: Boolean,
      default: false,
    },
    onlyPreviewHeadlines: {
      type: Boolean,
      default: false,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
    hideInstructions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    summaryType() {
      return this.$store.state.configuration.overviewUseSummary.toLowerCase();
    },
    summariesInShowcase() {
      return ['all', 'showcase'].includes(this.summaryType);
    },
    summaryInMain() {
      return ['all', 'main'].includes(this.summaryType);
    },
    mainText() {
      if (this.summaryInMain) {
        return this.documentSet.summaries.whole;
      }
      return this.documents.mainDocument.leadParagraph;
    },
    firstText() {
      if (this.summariesInShowcase) {
        return this.documents.firstSideSummary;
      }
      return this.documents.firstSideMainDocument.leadParagraph;
    },
    secondText() {
      if (this.summariesInShowcase) {
        return this.documents.secondSideSummary;
      }
      return this.documents.secondSideMainDocument.leadParagraph;
    },
    thirdText() {
      if (this.summariesInShowcase) {
        return this.documents.thirdSideSummary;
      }
      return this.documents.thirdSideMainDocument.leadParagraph;
    },
    countData() {
      const d = this.documents;
      const data = {};
      data[d.firstSideTitle] = d.firstSideDocuments.length;
      let other = this.documentSet.documents.length - d.firstSideDocuments.length;
      if (d.secondSideDocuments) {
        data[d.secondSideTitle] = d.secondSideDocuments.length;
        other -= d.secondSideDocuments.length;
      }
      if (d.thirdSideDocuments) {
        data[d.thirdSideTitle] = d.thirdSideDocuments.length;
        other -= d.thirdSideDocuments.length;
      }
      if (other > 0) {
        data[TEXT_ARTICLE_COUNT_OTHER] = other;
      }
      return data;
    },
    article1Route() {
      const {
        documents,
        identifier,
        file,
        smallPreview,
      } = this.$props;
      if (smallPreview) {
        return {
          name: 'overview',
          params: {
            file,
          },
        };
      }
      return this.route('article',
        { identifier, file, articleIndex: documents.firstSideMainDocument.index });
    },
    article2Route() {
      const {
        documents,
        identifier,
        file,
        smallPreview,
      } = this.$props;
      if (smallPreview) {
        return {
          name: 'overview',
          params: {
            file,
          },
        };
      }
      return this.route('article',
        { identifier, file, articleIndex: documents.firstSideMainDocument.index });
    },
    article3Route() {
      const {
        documents,
        identifier,
        file,
        smallPreview,
      } = this.$props;
      if (smallPreview) {
        return {
          name: 'overview',
          params: {
            file,
          },
        };
      }
      return this.route('article',
        { identifier, file, articleIndex: documents.firstSideMainDocument.index });
    },
    mainRoute() {
      const {
        documents,
        documentSet,
        identifier,
        file,
        smallPreview,
      } = this.$props;
      if (smallPreview) {
        return {
          name: 'overview',
          params: {
            file,
          },
        };
      }
      if (this.useUrl) {
        return {
          name: 'preview',
          params:
            {
              url: documents.mainDocument.url,
              id: documentSet.topic,
            },
        };
      }
      return this.route(
        'article',
        {
          identifier,
          file,
          articleIndex: documents.mainDocument.index,
        },
      );
    },
  },
};
</script>
