import Vue from 'vue';
import Vuex from 'vuex';
import seedrandom from 'seedrandom';
import _ from 'lodash';
import Cookies from 'js-cookie';
import router from '@/router';
import MostFrequentActorPerception
  from '@/components/overview/MostFrequentActorPerception.vue';
import AnonymousMostFrequentActorPerception
  from '@/components/overview/AnonymousMostFrequentActorPerception.vue';
import AllSides from '@/components/overview/AllSides.vue';
import AnonymousAllSides from '@/components/overview/AnonymousAllSides.vue';
import AnonymousClustering from '@/components/overview/AnonymousClustering.vue';
import Clustering from '@/components/overview/Clustering.vue';
import Plain from '@/components/overview/Plain.vue';
import {
  ARTICLE_DEFAULT,
  OVERVIEW_DEFAULT,
  getArticlePreset,
  getOverviewPreset, OVERVIEW_PRESETS,
} from '@/libraries/ConfigurationPresets';
import { answerRequest } from '@/libraries/SurveyApi';
// import query from '@/graphql/Configuration.gql';

const WORKER_ID_QUERY_PARAMETER = 'workerId';
const HIT_ID_QUERY_PARAMETER = 'hitId';
const ASSIGNMENT_ID_QUERY_PARAMETER = 'assignmentId';
const CONFIGURATION_PARSERS = {
  articleBiasCategoriesMode: x => x,
  articleHighlightMode: x => x,
  articleShowPolarity: x => x === '1',
  articleShowPolarityContext: x => x === '1',
  articleShowPoliticalSide: x => x === '1',
  articleShowTopBiasCategories: x => x === '1',
  articleShowPolarityTags: x => x === '1',
  articleShowPoliticalSideTags: x => x === '1',
  articlePolarityMethod: x => x,
  articlePolaritySeed: x => x,
  articleOpposingHeadlines: x => x,
  articleMainEntitySelection: x => x,
  articleShowClusterTags: x => x === '1',
  articleClusterType: x => x,
  articleClusterAlgorithm: x => x,
  articleShowAnonymousTags: x => x === '1',
  articleAnonymousTagType: x => x,
  overviewShowOtherLeadParagraphs: x => x,
  overviewPlainShowRepresentativeLeadParagraph: x => x === '1',
  overviewPlainShowArticleCount: x => x,
  overviewShowPolarityTags: x => x === '1',
  overviewShowPoliticalSideTags: x => x === '1',
  overviewShowArticleCount: x => x === '1',
  overviewShowBiasCategories: x => x,
  overviewShowPolarity: x => x === '1',
  overviewView: x => x,
  overviewPolarityMethod: x => x,
  overviewPolaritySeed: x => x,
  overviewMainEntitySelection: x => x,
  overviewSentimentBars: x => x,
  overviewSentimentBarMethod: x => x,
  overviewUseSummary: x => x,
  overviewClusterType: x => x,
  overviewClusterAlgorithm: x => x,
  overviewShowClusterTags: x => x === '1',
  overviewShowAnonymousTags: x => x === '1',
  overviewAnonymousTagType: x => x,
  overviewExplanation: x => x,
};

const IGNORE_CONFIGURATION_KEY = ['__typename'];

const OVERVIEW_COMPONENTS = {
  Plain,
  AllSides,
  MostFrequentActorPerception,
  Clustering,
  'Anonymous-AllSides': AnonymousAllSides,
  'Anonymous-MostFrequentActorPerception': AnonymousMostFrequentActorPerception,
  'Anonymous-Clustering': AnonymousClustering,
};

Vue.use(Vuex);

const ARTICLE_PRESET = 'newsalyze_form_article_preset';
const OVERVIEW_PRESET = 'newsalyze_form_overview_preset';

function getOverviewPresetName() {
  return window.localStorage.getItem(OVERVIEW_PRESET) || OVERVIEW_DEFAULT;
}

export default new Vuex.Store({
  state: {
    // main.ts updates the configuration with apollo.
    configuration: null,
    loading: 0,
    mturk: {},
    warnings: new Set(),
    errors: new Set(),
    currentTimingEvent: { startTime: Date.now() },
    timingEvents: [],
    history: [],
    historyPoppedState: 0,
  },
  getters: {
    overviewComponent: state => OVERVIEW_COMPONENTS[state.configuration.overviewView],
    historyWasPopped: state => state.historyPoppedState >= 1,
    getHistory: state => state.history,
    mturk: (state) => {
      const r = this.$route;
      let workerId = r.query[WORKER_ID_QUERY_PARAMETER];
      let hitId = r.query[HIT_ID_QUERY_PARAMETER];
      let assignmentId = r.query[ASSIGNMENT_ID_QUERY_PARAMETER];
      if (workerId === undefined || hitId === undefined || assignmentId === undefined) {
        if (process.env.VUE_APP_MTURK_ONLY_MODE === 'true') {
          console.warn(`At least one of the query-parameters ${WORKER_ID_QUERY_PARAMETER}, `
            + `${HIT_ID_QUERY_PARAMETER}, or `
            + `${ASSIGNMENT_ID_QUERY_PARAMETER} not set in URL. Setting all to ''.`);
        }
        workerId = '';
        hitId = '';
        assignmentId = '';
      }
      return {
        workerId,
        hitId,
        assignmentId,
      };
    },
    mturkEnabled: () => process.env.VUE_APP_MTURK_ONLY_MODE === 'true',
    articlePresetName: () => window.localStorage.getItem(ARTICLE_PRESET) || ARTICLE_DEFAULT,
    overviewPresetName: () => getOverviewPresetName(),
  },
  mutations: {
    updateLoading(state, value) {
      state.loading += value;
    },
    popState(state, value) {
      state.historyPoppedState = 2;
    },
    routeUpdate(state, value) {
      // console.log(getOverviewPresetName());
      const newEntry = [new Date().getTime(), window.location.href];
      if (Cookies.get('cookiesAccepted')) {
        fetch(answerRequest({
          url: window.location.href,
          view: getOverviewPresetName(),
          screen_x: window.innerHeight,
          screen_y: window.innerWidth,
          history: [newEntry],
          answers: null,
          id: null,
        }), { credentials: 'include' });
      }
      state.history.push(newEntry);
    },
    useOverviewConfigurationPreset(state, presetName = null) {
      if (process.env.VUE_APP_MTURK_ONLY_MODE !== 'true') {
        window.localStorage.setItem(OVERVIEW_PRESET, presetName);
        state.configuration = { ...state.configuration, ...getOverviewPreset(presetName) };
      }
    },
    useArticleConfigurationPreset(state, presetName = null) {
      if (process.env.VUE_APP_MTURK_ONLY_MODE !== 'true') {
        window.localStorage.setItem(ARTICLE_PRESET, presetName);
        state.configuration = { ...state.configuration, ...getArticlePreset(presetName) };
      }
    },
    useLastConfigurationPresets(state) {
      if (process.env.VUE_APP_MTURK_ONLY_MODE !== 'true') {
        const articlePreset = window.localStorage.getItem(ARTICLE_PRESET) || ARTICLE_DEFAULT;
        const overviewPreset = window.localStorage.getItem(OVERVIEW_PRESET) || OVERVIEW_DEFAULT;
        window.localStorage.setItem(ARTICLE_PRESET, articlePreset);
        window.localStorage.setItem(OVERVIEW_PRESET, overviewPreset);
        console.info(`The current preset is "${overviewPreset}". You can change the overview `
          + 'preset with by with the following code: '
          + `\`window.localStorage.setItem("${OVERVIEW_PRESET}", preset)\` where \`preset\` is any `
          + `of the following values: "${OVERVIEW_PRESETS.join('", "')}" and afterwards reload the page.`);
        // console.info('You can change the article preset with by with the following code: '
        // + `\`window.localStorage.getItem("${ARTICLE_PRESET}", preset)\` where \`preset\` is any `
        // + `of the following values: "${ARTICLE_PRESETS.join('", "')}" and afterwards reload the `
        // + `page.`);
        state.configuration = {
          ...getArticlePreset(articlePreset),
          ...getOverviewPreset(overviewPreset),
        };
      }
    },
    useDefaultConfigurationPresets(state) {
      if (process.env.VUE_APP_MTURK_ONLY_MODE !== 'true') {
        state.configuration = { ...getArticlePreset(), ...getOverviewPreset() };
      }
    },
    updateConfiguration(state, [newConfiguration, $route = {}]) {
      state.configuration = newConfiguration;
      if (process.env.VUE_APP_ALLOW_URL_CONFIG === 'true') {
        for (const [key, parser] of Object.entries(CONFIGURATION_PARSERS)) {
          if ($route.query[key] !== undefined) {
            state.configuration[key] = parser($route.query[key]);
          }
        }
      }
    },
    updateMturk(state, $route) {
      const { query } = $route;
      let workerId = query[WORKER_ID_QUERY_PARAMETER];
      let hitId = query[HIT_ID_QUERY_PARAMETER];
      let assignmentId = query[ASSIGNMENT_ID_QUERY_PARAMETER];
      let iteration = '';
      if ($route.params.iteration) {
        ({ iteration } = $route.params);
      }
      if (workerId === undefined || hitId === undefined || assignmentId === undefined) {
        if (process.env.VUE_APP_MTURK_ONLY_MODE === 'true') {
          console.warn(`At least one of the query-parameters ${WORKER_ID_QUERY_PARAMETER}, `
            + `${HIT_ID_QUERY_PARAMETER}, or ${ASSIGNMENT_ID_QUERY_PARAMETER} not set in URL. Setting all to ''.`);
        }
        workerId = '';
        hitId = '';
        assignmentId = '';
      }
      const newMturk = {
        workerId,
        hitId,
        assignmentId,
        iteration,
      };
      if (!_.isEqual(state.mturk, newMturk)) {
        state.mturk = newMturk;
      }
    },
    addWarning(state, warning) {
      state.warnings.add(warning);
    },
    addError(state, error) {
      state.errors.add(error);
    },
    removeWarnings(state) {
      state.warnings = new Set();
    },
    removeErrors(state) {
      state.errors = new Set();
    },
    assignToCurrentTimingEvent(state, data) {
      state.currentTimingEvent = Object.assign(state.currentTimingEvent, data);
    },
    pushCurrentTimingEvent(state, data) {
      state.currentTimingEvent = Object.assign(state.currentTimingEvent, data);
      state.currentTimingEvent.endTime = Date.now();
      state.timingEvents.push(state.currentTimingEvent);
      state.currentTimingEvent = { startTime: Date.now() };
    },
    resetTimingEvents(state) {
      state.timingEvents = [];
    },
    checkWarnings(state, response) {
      const { data } = response;
      if (data) {
        for (const value of Object.values(data)) {
          if (value.warnings) {
            for (const warning of value.warnings) {
              state.warnings.add(warning);
            }
          }
        }
      }
    },
  },
  actions: {
  },
  modules: {
  },
});
