<template>
  <ul
    class="list-disc headline-list"
    :class="{'expandable':
      leadParagraphs === 'click'}">
    <li
      v-for="doc in this.smallDocuments"
      :class="{'expanded': showLeadDocuments.includes(doc)}"
      :key="doc.index"><!--
      --><span class="expand-item" @click="toggleLead(doc)"></span>
      <h3
        class="article-headline"
        :class="{'cursor-pointer':
          leadParagraphs === 'click'}"
        @click="toggleLead(doc)"><!--
      --><router-link
        :to="useUrl
        ? { name: 'preview', params: {url: doc.url, id: documentSet.topic} }
        : route('article', { identifier, file, articleIndex: doc.index })">{{
          doc.title ? doc.title : '[MISSING TITLE]'
        }}<!--
      --></router-link><!-- -->
      <EntityPolarityBarChart
        v-if="showArticleBarChartPolarities"
        class="inline-block pl-2 pr-1"
        :barChartPolarities="doc.sentimentBarData"
        :visualizeLarge="false" /><!--
      --><Tags
        :showPoliticalOrientation="politicalSideTags"
        :showPolarity="polarityTags"
        :showCluster="clusterTags"
        :showAnonymous="anonymousTags"
        :document="doc"
      /></h3>
    <p
      v-show="leadParagraphs === 'always'
        || showLeadDocuments.includes(doc)"
      v-if="leadParagraphs !== 'off'">{{ doc.leadParagraph }} [&mldr;]</p>
    </li>
  </ul>
</template>
<script>
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import POLARITIES from '@/libraries/Polarities.js';
import DocumentParser from '@/libraries/DocumentParser.js';
import Tags from '@/components/Tags.vue';
import EntityPolarityBarChart from '@/components/EntityPolarityBarChart.vue';

export default {
  name: 'HeadlineList',
  components: {
    // 'article-headlines': ArticleHeadlines,
    EntityPolarityBarChart,
    Tags,
  },
  DocumentParser,
  props: {
    documentSet: {
      type: Object,
      required: true,
    },
    displayDocuments: {
      type: Array,
      required: true,
    },
    identifier: String,
    max: Number,
    polarityTags: {
      type: Boolean,
      default: true,
    },
    politicalSideTags: {
      type: Boolean,
      default: true,
    },
    clusterTags: {
      type: Boolean,
      default: true,
    },
    anonymousTags: {
      type: Boolean,
      default: true,
    },
    leadParagraphs: {
      type: String,
      default: 'off',
    },
    fullCoverage: {
      type: Boolean,
      defaults: false,
    },
    showArticleBarChartPolarities: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      useUrl: process.env.VUE_APP_LINK_TO_ARTICLE_VIEW === '0',
      showLeadDocuments: [],
    };
  },
  mounted() {
    this.showLeadDocuments.push(this.smallDocuments[0]);
  },
  computed: {
    smallDocuments() {
      const clustersCount = {};
      const anonymousCount = {};
      const polaritiesCount = {};
      const politicalSidesCount = {};
      let docs = [];
      for (const doc of this.displayDocuments) {
        // Check how often the documents before already had the same values
        const cClusterCount = clustersCount[doc.polarityClusterData.name] + 1 || 1;
        const cAnonymousCount = anonymousCount[doc.anonymousGroup.name] + 1 || 1;
        const cPolarityCount = polaritiesCount[doc.mainEntityPolarity.key] + 1 || 1;
        let cPoliticalSideCount = 1;
        if (doc.politicalOrientation) {
          cPoliticalSideCount = politicalSidesCount[doc.politicalOrientation.key] + 1 || 1;
        }
        const score = this.calculateScore(
          [cClusterCount, cAnonymousCount, cPolarityCount, cPoliticalSideCount],
          [this.clusterTags, this.anonymousTags, this.polarityTags, this.politicalSideTags],
        );
        docs.push([score, doc]);
        clustersCount[doc.polarityClusterData.name] = cClusterCount;
        anonymousCount[doc.anonymousGroup.name] = cAnonymousCount;
        polaritiesCount[doc.mainEntityPolarity.key] = cPolarityCount;
        if (doc.politicalOrientation) {
          politicalSidesCount[doc.politicalOrientation.key] = cPoliticalSideCount;
        }
      }
      // Lower score = more diverse = better
      docs.sort((first, second) => first[0] - second[0]);
      docs = docs.map(d => d[1]);

      if (!this.fullCoverage) {
        return docs.slice(0, this.max);
      }
      return docs;
    },
  },
  methods: {
    toggleLead(doc) {
      const index = this.showLeadDocuments.indexOf(doc);
      if (index === -1) {
        this.showLeadDocuments.push(doc);
      } else {
        this.showLeadDocuments.splice(index, 1);
      }
    },
    calculateScore(appearances, mask) {
      return appearances
        .filter((count, index) => mask[index] > 0)
        .reduce((x, y) => x + y, 0);
    },
  },
};
</script>
