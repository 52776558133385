<template>
  <div ref="entityPolarityBarChart"
       :aria-label="tooltip"
       data-balloon-pos="up">
  </div>
</template>

<script>
import EntityPolarityBarChart from '@/libraries/EntityPolarityBarChart.js';

export default {
  name: 'EntityPolarityBarChart',
  props: {
    barChartPolarities: {
      type: Array,
      required: true,
    },
    visualizeLarge: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reloadNextTickDone: true,
    };
  },
  computed: {
    root() {
      return this.$refs.entityPolarityBarChart;
    },
    tooltip() {
      if (this.visualizeLarge) {
        return 'Sentiment in this group of articles concerning the most frequent entities';
      }
      return 'Sentiment in this article concerning the most frequent entities';
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const chart = new EntityPolarityBarChart(
        this.root,
        this.barChartPolarities,
      );
      if (this.visualizeLarge) {
        chart.visualizeLarge();
      } else {
        chart.visualizeSmall();
      }
    },
    remove() {
      let child = this.root.lastElementChild;
      while (child) {
        this.root.removeChild(child);
        child = this.root.lastElementChild;
      }
    },
    reload() {
      this.remove();
      this.load();
    },
    reloadNextTick() {
      this.reloadNextTickDone = false;
      this.$nextTick(() => {
        if (!this.reloadNextTickDone) {
          this.reload();
        }
        this.reloadNextTickDone = true;
      });
    },
  },
  watch: {
    documentPolarity() {
      this.reloadNextTick();
    },
    mainDocumentPolarity() {
      this.reloadNextTick();
    },
  },
};
</script>
