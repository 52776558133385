<template>
  <div class="mt-20">
    <div class="m-auto max-w-6xl">
      <h1>Welcome to Newsalyze!</h1>

      <p>
        Newsalyze is a free-to-use website that allows you to view news coverage on current
        events. In contrast to other news apps, we do not only show you the news, but also up to
        three perspective for each event. This way, we hope you will be able to get a broader
        understanding of coverage on an event. One of the motivations of Newsalyze is to counter
        echo chambers and news bubbles, and we hope you will enjoy using this website.
      </p>
      <p>
        Newsalyze is a research project by groups at the University of Zurich and the Heidelberg
        Academy of Sciences. As such, please note that we are conducting a study on how people
        interact with the news and also with news from various perspectives. We analyze how users
        interact with this website. More information can be found in our
        <router-link to="privacy-policy" class="underline">privacy policy</router-link> but it
        basically comes down to:

      <ul>
        <li>
          We never store any personal data, except if you provide such data explicitly, e.g., when
          submitting an answer in our survey
        </li>
        <li>
          We store your interactions with this website <b>anonymously</b>
        </li>
        <li>
          Any data will be used only for non-commercial, academic purposes
        </li>
        <li>
          We will never sell your data to third parties
        </li>
      </ul>
      </p>

      <h3>Cookies</h3>
      When using this website, you allow us setting the following cookies:
      <ul>
        <li>
          Cookie banner (<code>cookiesAccepted</code>):
          Hiding this cookie banner for you in the future.
        </li>
        <li>
          Session ID (<code>sessionID</code>):
          Knowing you are the same person when you reload the page.
        </li>
        <li>
          Delivered survey forms (<code>surveyForms</code>):
          Keeping track on which surveys you thankfully participated to not bother you again.
        </li>
      </ul>
      <span v-if="cookiesAccepted">
          You already accepted to the usage terms of this website.
          <br />
          <button @click="revokeCookies" class="bg-gray-300 py-2 px-8 inline-block text-lg my-2">
            Revoke
          </button>
      </span>
      <span v-else>
        <button @click="acceptCookies" class="bg-gray-300 py-2 px-8 inline-block text-lg my-2">
          Accept Privacy Policy and Cookies
        </button>
        <br />
        You can always come back to this site via "Usage and Cookies" at the footer of this site and
        reject the above stated terms.
      </span>
    </div>
    <footer class="text-center mt-2">
      <router-link to="imprint" class="underline">Imprint</router-link> /
      <router-link to="privacy-policy" class="underline">Privacy Policy</router-link> /
      <router-link to="usage" class="underline">Usage and Cookies</router-link>
    </footer>
  </div>
</template>
<script lang="js">
import Cookies from 'js-cookie';
import router from '@/router';

const usage = {
  name: 'usage',
  data: () => ({
    cookiesAccepted: Cookies.get('cookiesAccepted'),
  }),
  methods: {
    acceptCookies() {
      Cookies.set('cookiesAccepted', true, { expires: 365, sameSite: 'Lax' });
      const { redirect } = router.currentRoute.query;
      if (redirect) {
        if (redirect.includes('//')) {
          window.location.href = router.currentRoute.query.redirect;
        } else {
          router.push(JSON.parse(atob(redirect)));
        }
      }
      this.cookiesAccepted = true;
      this.$emit('cookiesAccepted');
    },
    revokeCookies() {
      Cookies.remove('cookiesAccepted');
      Cookies.remove('surveyForms');
      Cookies.remove('sessionID');
      this.cookiesAccepted = false;
      this.$emit('cookiesRevoked');
    },
  },
};

export default usage;
</script>
