<template functional>
  <span class="headline-tags">
    <span
      v-if="props.showPoliticalOrientation"
      :aria-label="props.document.politicalOrientation.articleTooltipText"
      :class="props.document.politicalOrientation.labelClass"
      data-balloon-pos="up"
      class="label">{{ props.document.politicalOrientation.text }}</span>
    <span
      v-if="props.showPolarity"
      class="label"
      :class="props.document.mainEntityPolarity.labelClass"
      :aria-label="props.document.mainEntityPolarity.articleTooltipText"
      data-balloon-pos="up"
      >{{ props.document.mainEntityPolarity.text }}</span>
    <span
      v-if="props.showCluster"
      :aria-label="props.document.polarityClusterData.articleTooltipText"
      data-balloon-pos="up"
      :class="props.document.polarityClusterData.labelClass"
      class="label">{{ props.document.polarityClusterData.name }}</span>
    <span
      v-if="props.showAnonymous"
      :aria-label="props.document.anonymousGroup.articleTooltipText"
      :class="props.document.anonymousGroup.labelClass"
      data-balloon-pos="up"
      class="label">{{ props.document.anonymousGroup.name }}</span>
  </span>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    showPoliticalOrientation: {
      default: false,
      type: Boolean,
    },
    showPolarity: {
      default: false,
      type: Boolean,
    },
    showCluster: {
      default: false,
      type: Boolean,
    },
    showAnonymous: {
      default: false,
      type: Boolean,
    },
    document: {
      required: true,
      type: Object,
    },
  },
};
</script>
